import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import Container from 'react-bootstrap/Container';
import update from 'immutability-helper';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import { StepOneUrl } from '../../containers/createBot/StepOneUrl';
import { StepTwoName } from '../../containers/createBot/StepTwoName';
import { StepThreeTask } from '../../containers/createBot/StepThreeTask';
import { StepFourSchedule } from '../../containers/createBot/StepFourSchedule';
import { Finish } from '../../containers/createBot/Finish';
import ApiClient from '../../util/ApiClient';
import '../../css/createMain.css';
import moment from 'moment'
import { getParameterByName } from '../../util/queryString';
import { Templates } from '../../components/Templates'
import { SeriesQuestions } from '../../containers/Series/SeriesQuestions';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';

const options = {
    autoConfig: true,
    debug: false
};
ReactPixel.init('718156363356551', null, options);
TiktokPixel.init('CG99DTBC77UCLSGQSU3G');

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
function UpgradeNeededModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>Upgrade Required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h3>{props.message}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Button onClick={() => { window.location = '/subscription' }}>Upgrade Subscription</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    );
}
function SyncWarning(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>Active Bot Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h3>Need to remove, add or arrange a step? Please contact our <a target='_blank' href='/support'>support team</a> with details for approval.</h3>
                        <span className="text-muted" style={{ wordWrap: 'break-word' }}>Not sure about your changes? Contact our <a target='_blank' href='/support'>support team.</a></span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {props.showRemoveAction &&
                    <Button hidden={!props.hasAccess} variant="dark" onClick={props.removeAndRebuild} disabled={props.removingStep}>
                        Remove & Build
                    </Button>}
                {props.showInjectModeAction &&
                    <Button hidden={!props.hasAccess} variant="dark" onClick={props.enterInjectMode}>
                        Inject & Build
                    </Button>}
                <Button hidden={!props.hasAccess} variant="danger" onClick={props.continueWithBotChanges}>
                    Continue with Changes
                </Button>
                <Button variant="primary" onClick={() => window.location.reload()}>
                    Discard Changes
                </Button>
            </Modal.Footer>
        </Modal >

    );
}
function UrlChangeWarning(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>URL Change Detected</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h3>Need to change the website url? Please contact our <a target='_blank' href='/support'>support team</a> with details for approval.</h3>
                        <span className="text-muted" style={{ wordWrap: 'break-word' }}>Not sure about your changes? Contact our <a target='_blank' href='/support'>support team.</a></span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button hidden={!props.hasAccess} variant="danger" onClick={props.forceUrlChange} disabled={props.changingUrl}>
                    Force Change
                </Button>
                <Button variant="primary" onClick={() => window.location.reload()}>
                    Discard Changes
                </Button>
            </Modal.Footer>
        </Modal >

    );
}
function LoadingModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={7000}
                        />
                    </Col>
                </Row>

            </Modal.Body>
        </Modal >
    );
}
function InjectModeModal(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>Inject Html Step</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlID">
                            <Form.Label>ID</Form.Label>
                            <Form.Control as="textarea" rows={2} onChange={props.injectModeElementIdChange} value={props.injectModeElementId} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlClass">
                            <Form.Label>Class</Form.Label>
                            <Form.Control as="textarea" rows={2} onChange={props.injectModeElementClassChange} value={props.injectModeElementClass} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="controlXpath">
                            <Form.Label>Xpath</Form.Label>
                            <Form.Control as="textarea" rows={2} onChange={props.injectModeElementXpathChange} value={props.injectModeElementXpath} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={props.injectingHtml} onClick={props.injectHtmlStep}>
                    Rebuild
                </Button>
            </Modal.Footer>
        </Modal >

    );
}
function BusinessBotAlert(props) {
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header>
                <Modal.Title>Bot-It for Business</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <h3>Please check your email for instructions on building your first business bot.</h3>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide} >
                    Close
                </Button>
            </Modal.Footer>
        </Modal >

    );
}
@inject('rootStore')
@observer
export default class Create extends Component {
    constructor() {
        super();
        this.textInput = null;
        this.setTextInputRef = element => {
            this.textInput = element;
        };
        this.state = {
            bot: {
                url: "https://",
                taskInfo: [],
                botName: '',
                runTimes: [],
                acceptedLoginConsent: false,
                allowCustomRedirectUrl: false,
                forceScheduleReset: false,
                runLocally: false,
                hideFailedResults: false,
                skipRunTimeStep: false,
                skipIPSetup: false,
                skipDateInCreaseIfFail: false,
                extendPageLoadTimeout: false,
                redirectUrl: '',
                proxyGroup: null,
                driverHub: null,
                dataCenterLocation: null,
                burstDuration: null,
                burstCount: null,
                maxDelayCount: null,
                iPSetting: null
            },
            dataCenterLocationOptions: [],
            driverHubOptions: [],
            proxyGroupOptions: [],
            chromeExtensionOptions: [],
            iPSettings: [],
            showLoginConsentWarning: false,
            showUpdateRedirectUrlModalShow: false,
            adminPanelModalShow: false,
            afterLoginConsentAccepted: undefined,
            loginTaskWasAdded: false,
            step: 0,
            stepOneError: '',
            disableStep1Button: true,
            disableStep2Button: true,
            disableLoginTaskButton: true,
            disableInputTaskButton: true,
            disableSelectTaskButton: true,
            disableDatePickerTaskButton: true,
            disableListSelectorTaskButton: true,
            disableButtonTaskButton: true,
            disableEmailResultsButton: true,
            disableSmsResultsButton: true,
            disableScheduleButton: true,
            modalShow: false,
            loginModalShow: false,
            inputLabelHelperModalShow: false,
            selectOptionLabelHelperModalShow: false,
            datePickerHelperModalShow: false,
            listSelectorHelperModalShow: false,
            buttonLabelHelperModalShow: false,
            emailModalShow: false,
            smsModalShow: false,
            currentTaskPosition: 0,
            showLoginTaskPassword: false,
            hideAssignButton: false,
            dragInProgress: false,
            dragInProgressSchedule: false,
            confirmDeleteModalShow: false,
            pendingDeleteTask: {},
            addScheduleModalShow: false,
            hideAddTimeButton: false,
            pendingDeleteSchedule: {},
            saveButtonText: 'Save',
            disableSaveButton: false,
            confirmDeleteScheduleModalShow: false,
            currentSchedulePosition: 0,
            supportedTimeZones: [],
            repeatersAllowed: [],
            showAnimation: false,
            finishCreateBotModalShow: false,
            isBotComplete: false,
            wasCreatedNewCloseHelper: false,
            subscriptionName: 'Starter',
            showUpgradeNeededModal: false,
            showSyncWarningModal: false,
            showChangeUrlWarningModal: false,
            showBusinessBotAlertModal: false,
            changingUrl: false,
            showUrlHelper: false,
            hideAddNewSearchOption: false,
            showSeriesQuestionnaireModal: false,
            currentSeriesId: '',
            seriesTitle: '',
            exampleBotName: '',
            showQuestionImageHelper: false,
            series: [],
            currentQuestionIterator: 0,
            previousStepCounter: 0,
            isAnswerButtonDisabled: true,
            hideErrorMessage: true,
            errorMessageText: '',
            showQuestionnairePassword: false,
            loginConsentAgreedForQuestionnaire: false,
            inPositiveRepeatMode: false,
            inNegativeRepeatMode: false,
            inStepPickerMode: false,
            positiveRepeatCount: 0,
            negativeRepeatCount: 0,
            stepPickerRepeatCount: 0,
            repeaterQuestionIndex: 0,
            upgradeMessage: "You have reached the limit of Bots you can create with your current subscription.",
            isLoading: true,
            botStepList: [],
            columnFieldList: [],
            botStepListIterator: 0,
            columnFieldListIterator: 0,
            hideFrequencyErrorMessage: true,
            hideBackButton: false,
            masterOrderCounter: 0,
            emailAddress: '',
            hasAccess: false,
            botCountryOptions: [],
            supportedGolfCourses: [],
            supportedTennisCourts: [],
            supportedPickleBallCourts: [],
            supportedCampingSites: [],
            supportedDinnerSites: [],
            templateOptions: [],
            customTemplateOptions: [],
            activeMessage: 'Not Ready',
            startedActive: false,
            tags: [],
            miniBotTags: [{ id: '7', text: '7' }, { id: '8', text: '8' }, { id: '9', text: '9' }, { id: '10', text: '10' }, { id: '11', text: '11' }, { id: '12', text: '12' }],
            miniBotFinalTag: null,
            subCustomer: false,
            premiumBotSelected: null,
            premiumBotSelectedName: null,
            premiumConsentTracker: false,
            showInjectModeAction: false,
            showInjectHtmlModal: false,
            injectMode: false,
            injectingHtml: false,
            injectModeElementId: null,
            injectModeElementClass: null,
            injectModeElementXpath: null,
            injectModelPlacement: 0,
            injectedTask: null,
            pendingTaskRemove: null,
            showRemoveAction: false,
            removingStep: false,
            existingDay: null,
            miniBotSearchType: null,
            isEditing: false
        };
    }

    componentDidMount() {
        const rootStore = this.props.rootStore;
        var statement = this.buildMiniBotORStatement();
        this.setState({ miniBotFinalTag: statement });
        var firstTimeBusiness = getParameterByName('business', window.location.href);
        if (firstTimeBusiness == "elite") this.showBusinessBotAlert(true);
        ApiClient.get('user/profile').then((profileResponse) => {
            if (profileResponse && profileResponse.data.subscriptionName) {
                this.setState({ subscriptionName: profileResponse.data.subscriptionName, emailAddress: profileResponse.data.emailAddress, subCustomer: profileResponse.data.subscriptionName == "Starter", hasAccess: profileResponse.data.role > 3 }, () => {
                    ApiClient.get('user/maxed/bot/creations').then((maxCreationResponse) => {
                        var botId = getParameterByName('bid', window.location.href);
                        if (maxCreationResponse.data == true && !botId && !this.state.subCustomer) {
                            this.setState({ isLoading: false, showUpgradeNeededModal: true })
                        }
                        else {
                            ApiClient.get('bot/v2/support/package').then((supportResponse) => {
                                this.setState({ isEditing: botId, supportedTimeZones: supportResponse.data.timezones, repeatersAllowed: supportResponse.data.dateRepeaters, templateOptions: supportResponse.data.templateOptions, customTemplateOptions: supportResponse.data.customBotTemplates }, () => {
                                    if (this.state.hasAccess) {
                                        ApiClient.get('bot/support/admin/package').then((adminResponse) => {
                                            this.setState({
                                                dataCenterLocationOptions: adminResponse.data.dataCenterOptions,
                                                driverHubOptions: adminResponse.data.driverHubOptions,
                                                proxyGroupOptions: adminResponse.data.proxyGroupOptions,
                                                botCountryOptions: adminResponse.data.botCountryOptions,
                                                chromeExtensionOptions: adminResponse.data.chromeExtensionOptions,
                                                iPSettings: adminResponse.data.iPSettings
                                            }, () => {
                                                var start = getParameterByName('autostart', window.location.href);
                                                if (start)
                                                    this.setState({ step: 0 }, () => this.startSeries(start, this.getSeriesTitleDeeLink(start),
                                                        this.getSeriesExampleNameDeeLink(start)));
                                            })
                                        }).catch((error) => {
                                        });
                                    }
                                })
                            }).catch((error) => {
                            });
                            this.handleSeries();

                        }

                    }).catch((error) => {
                        alert(error.response.error)
                    });

                });
            }
        }).catch((error) => {
        });
    }
    handleSeries = () => {
        var botId = getParameterByName('bid', window.location.href);
        if (!botId) {
            var seriesId = getParameterByName('series', window.location.href);
            if (seriesId) {
                if (seriesId == "self") this.handleBuildMyOwn();
                else {
                    this.setState({ step: 0 }, () => this.startSeries(seriesId, this.getSeriesTitleDeeLink(seriesId),
                        this.getSeriesExampleNameDeeLink(seriesId)));
                }
            }
            else this.showIsLoading(false);
        }
        else {
            this.setState({ isLoading: true }, () => {
                ApiClient.get('bot/access/' + botId).then((access) => {
                    ApiClient.get('bot/details/' + botId).then((response) => {
                        this.setState({
                            step: 1, isLoading: false, bot: response.data, disableStep1Button: false, disableStep2Button: false,
                            activeMessage: response.data.isActive ? "Active" : 'Not Ready', startedActive: response.data.isActive
                        })
                    }).catch((error) => { });
                }).catch((error) => {
                    alert("Unauthorize Access");
                    window.location = "/";
                });
            });
        }
    }
    seriesAllowed(seriesId) {
        if (this.state.subCustomer) {
            return true;
        }
        else if (this.state.subscriptionName == "Starter") {
            return false;
        }
        else if ((this.state.subscriptionName == "Basic" || this.state.subscriptionName == "Basic Plus") && (seriesId == "605c36236f04a82ed6d6b9bb" || seriesId == "605c38336f04a82ed6d6b9bd")) {
            return false;
        }
        else if ((this.state.subscriptionName == "Standard" || this.state.subscriptionName == "Standard Plus") && seriesId == "605c38336f04a82ed6d6b9bd") {
            return false;
        }
        else return true;
    }
    getSeriesTitleDeeLink(seriesId) {
        switch (seriesId) {
            case "605c31916f04a82ed6d6b9b8":
                return "Login Results"
            case "605c31976f04a82ed6d6b9b9":
                return "Product Search";
            case "605c36236f04a82ed6d6b9bb":
                return "Appointment";
            case "605c38336f04a82ed6d6b9bd":
                return "Import Data";
            default:
                return "New Bot Setup";
        }
    }
    getSeriesExampleNameDeeLink(seriesId) {
        switch (seriesId) {
            case "605c31916f04a82ed6d6b9b8":
                return "Example: Online Raffle";
            case "605c31976f04a82ed6d6b9b9":
                return "Example: Air Jordan 5 Retro GS Raging Bull";
            case "605c36236f04a82ed6d6b9bb":
                return "Example: Book Tee Time";
            case "605c38336f04a82ed6d6b9bd":
                return "Example: Migrate 2020 Warehouse Inventory";
            default:
                return "Example: Book Tee Time";
        }
    }
    handleShowingUrlHelperModalFromClip() {
        var showModal = localStorage.getItem("DontShowHelperPopupSelected");
        if (showModal !== "true" && this.state.step !== 0) {
            this.setState({ showUrlHelper: true })
        }
    }
    handleUrlChange = (e) => {
        var url = e.target.value;
        this.setState({ stepOneError: '' })

        if (url && url.length > 0) {
            if (!url.startsWith('https://')) {
                this.setState({ disableStep1Button: true, stepOneError: 'Urls must start with https://' })

            } else {
                var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

                var regex = new RegExp(expression);

                if (url.match(regex)) {
                    this.setState({ disableStep1Button: false, bot: { ...this.state.bot, url: url } }, () => { this.checkUrlSync(); });
                } else {
                    this.setState({ disableStep1Button: true, bot: { ...this.state.bot, url: url } });
                }

            }
        }
        else {
            this.setState({ disableStep1Button: false, bot: { ...this.state.bot, url: 'https://' } });
        }
    }
    handleShowUrlHelper = (show) => {
        this.setState({ showUrlHelper: show });
        if (show === false) {
            navigator.clipboard.readText()
                .then(url => {
                    if (this.state.step == 1 && this.state.bot.url === "https://" && url && url.length > 0) {
                        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

                        var regex = new RegExp(expression);

                        if (url.match(regex)) {
                            this.setState({ disableStep1Button: false, bot: { ...this.state.bot, url: url } });
                        }
                    }
                })
                .catch(err => {
                    console.log('No access to clipboard', err);
                });
        }
    }
    handleDontShowUrlHelperChange = () => {
        localStorage.setItem("DontShowHelperPopupSelected", "true");
        this.handleShowUrlHelper(false);
    }
    onSaveUrlNextClick = () => {
        this.setState({ step: 2 })
    }
    handleNameChange = (e) => {
        var name = e.target.value;
        if (name && name.length > 0) {
            this.setState({ disableStep2Button: false, bot: { ...this.state.bot, botName: name } });
        } else {
            this.setState({ disableStep2Button: true, bot: { ...this.state.bot, botName: name } });
        }
    }
    onSaveNameClick = () => {
        this.setState({ step: 3 })
    }
    handleTaskModalShow = (show) => {
        this.setState({ modalShow: show });
    }

    handleLoginModalShow = (show, clearData, editing) => {
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearTwoTaskData(
                { loginTask: { userName: { $set: '' }, password: { $set: '' } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.userName : null },
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.password : null },
                { ...this.state.bot },
                { loginModalShow: show, wasCreatedNewCloseHelper: false });

        } else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { loginModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { loginModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateTwoTask(
                show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.userName,
                this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.password,
                { ...this.state.bot },
                handleHideShowObject)
        }
    }
    onClickSaveLoginDetails = () => {
        this.handleLoginModalShow(false);
        this.checkSyncingRequired();
    }
    handleLoginUsernameChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { loginTask: { userName: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateUserNamePassword();
        });
    }
    handleLoginPasswordChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { loginTask: { password: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateUserNamePassword();
        });
    }
    validateUserNamePassword = () => {
        if (this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.userName &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.userName.length > 0 &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.password &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].loginTask.password.length > 0) {
            this.setState({ disableLoginTaskButton: false });
        }
        else {
            this.setState({ disableLoginTaskButton: true });
        }
    }
    onShowPasswordChange = (e) => {
        this.setState({ showLoginTaskPassword: e.target.checked })
    }
    handleInputHelperLabelModalShow = (show, clearData, editing) => {
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearTwoTaskData(
                { textFieldTask: { label: { $set: '' }, inputValue: { $set: '' } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.label : null },
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.inputValue : null },
                { ...this.state.bot },
                { inputLabelHelperModalShow: show, wasCreatedNewCloseHelper: false });

        } else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { inputLabelHelperModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { inputLabelHelperModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateTwoTask(
                show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.label,
                this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.inputValue,
                { ...this.state.bot },
                handleHideShowObject)
        }
    }
    handleInputHelperLabelChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { textFieldTask: { label: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateInputHelperData();
        });
    }
    handleInputValueChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { textFieldTask: { inputValue: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateInputHelperData();
        });
    }
    handleIsSpreadSheetBeforeForm = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { isBeforeSpreadSheetForm: { $set: e.target.checked } } })
            }
        });
    }
    handleIsSpreadSheetFormField = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { isSpreadSheetFormField: { $set: e.target.checked } } })
            }
        });
    }
    validateInputHelperData = () => {
        if (this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.label &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.label.length > 0 &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.inputValue &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].textFieldTask.inputValue.length > 0) {
            this.setState({ disableInputTaskButton: false });
        }
        else {
            this.setState({ disableInputTaskButton: true });
        }
    }
    onClickSaveInputFieldDetails = () => {
        this.handleInputHelperLabelModalShow(false);
        this.checkSyncingRequired(true);
    }
    handleSelectOptionHelperLabelModalShow = (show, clearData, editing) => {
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearTwoTaskData(
                { selectTask: { label: { $set: '' }, option: { $set: '' } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.label : null },
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.option : null },
                { ...this.state.bot },
                { selectOptionLabelHelperModalShow: show, wasCreatedNewCloseHelper: false });

        } else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { selectOptionLabelHelperModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { selectOptionLabelHelperModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateTwoTask(
                show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.label,
                this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.option,
                { ...this.state.bot },
                handleHideShowObject)
        }
    }
    handleSelectOptionHelperLabelChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { selectTask: { label: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateSelectOptionHelperData();
        });
    }
    handleSelectValueChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { selectTask: { option: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateSelectOptionHelperData();
        });
    }
    validateSelectOptionHelperData = () => {
        if (this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.label &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.label.length > 0 &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.option &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].selectTask.option.length > 0) {
            this.setState({ disableSelectTaskButton: false });
        }
        else {
            this.setState({ disableSelectTaskButton: true });
        }
    }
    onClickSaveSelectOptionDetails = () => {
        this.handleSelectOptionHelperLabelModalShow(false);
        this.checkSyncingRequired(true);
    }
    handleDatePickerModalShow = (show, clearData, editing) => {
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearTwoTaskData(
                { datePickerTask: { label: { $set: '' }, option: { $set: '' }, daysAutoIncreaseDateValue: { $set: 'None' } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.label : null },
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.option : null },
                { ...this.state.bot },
                { datePickerHelperModalShow: show, wasCreatedNewCloseHelper: false });

        } else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { datePickerHelperModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { datePickerHelperModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateTwoTask(
                show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.label,
                this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.option,
                { ...this.state.bot },
                handleHideShowObject)
        }
    }
    handleDatePickerOptionChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { datePickerTask: { label: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateDatePickerHelperData();
        });
    }
    handleDatePickerValueChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { datePickerTask: { option: { $set: e.toDateString() } } } })
            }
        }, () => {
            this.validateDatePickerHelperData();
        });
    }
    handleDatePickerRepeaterChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { datePickerTask: { daysAutoIncreaseDateValue: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateDatePickerHelperData();
        });
    }
    validateDatePickerHelperData = () => {
        if (this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.label &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.label.length > 0 &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.option &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].datePickerTask.daysAutoIncreaseDateValue) {
            this.setState({ disableDatePickerTaskButton: false });
        }
        else {
            this.setState({ disableDatePickerTaskButton: true });
        }
    }
    onClickSaveDatePickerOptionDetails = () => {
        this.handleDatePickerModalShow(false);
        this.checkSyncingRequired();
    }
    handleListSelectorModalShow = (show, clearData, editing) => {
        if (show && this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.options) {
            var orTags = this.destroyORStatement(this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.options);
            if (orTags) this.setState({ tags: orTags });
        }
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearTwoTaskData(
                { listSelectorTask: { label: { $set: '' }, options: { $set: [{ compareText: '', exactMatch: false }] } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.label : null },
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.options : null },
                { ...this.state.bot },
                { listSelectorHelperModalShow: show, wasCreatedNewCloseHelper: false });

        } else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { listSelectorHelperModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { listSelectorHelperModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateTwoTask(
                show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.label,
                this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.option,
                { ...this.state.bot },
                handleHideShowObject)
        }
    }
    handleListSelectorOptionChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { label: { $set: e.target.value } } } })
            }
        }, () => {
            this.validateListSelectorHelperData();
        });
    }
    handleListSelectorValueOptionChange = (i, isChecked) => (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { options: { $splice: [[i, 1, { compareText: e.target.value, exactMatch: isChecked }]] } } } })
            }
        }, () => {
            this.validateListSelectorHelperData();
        });
    }
    handleListSelectorExactMatchValueOptionChange = (i, compareText) => (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { options: { $splice: [[i, 1, { exactMatch: e.target.checked, compareText: compareText }]] } } } })
            }
        }, () => {
            this.validateListSelectorHelperData();
        });
    }
    addSearchOption = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { options: { $push: [{ compareText: '', exactMatch: false }] } } } })
            }
        }, () => {
            if (this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.options.length > 1) {
                this.setState({ hideAddNewSearchOption: true });
            }
            this.validateListSelectorHelperData();
        });
    }
    deleteItem = (index) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { options: { $splice: [[index, 1]] } } } })
            }
        }, () => {
            if (this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.options.length < 2) {

                this.setState({ hideAddNewSearchOption: false });
            }
            this.validateListSelectorHelperData();
        });
    }
    validateListSelectorHelperData = () => {
        if (this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.label &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.label.length > 0 &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.options &&
            this.state.bot.taskInfo[this.state.currentTaskPosition].listSelectorTask.options[0].compareText.length > 0) {
            this.setState({ disableListSelectorTaskButton: false });
        }
        else {
            this.setState({ disableListSelectorTaskButton: true });
        }
    }
    onClickSaveListSelectorOptionDetails = () => {
        this.handleListSelectorModalShow(false);
        this.checkSyncingRequired(true);
    }
    handleButtonHelperLabelModalShow = (show, clearData, editing) => {
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearOneTaskData(
                { buttonTask: { label: { $set: '' } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].buttonTask.label : null },
                { ...this.state.bot },
                { buttonLabelHelperModalShow: show, wasCreatedNewCloseHelper: false });
        } else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { buttonLabelHelperModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { buttonLabelHelperModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateOneTask(
                show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].buttonTask.label,
                { ...this.state.bot },
                handleHideShowObject)
        }
    }
    handleButtonHelperLabelChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { buttonTask: { label: { $set: e.target.value } } } })
            }
        }, () => {
            if (this.state.bot.taskInfo[this.state.currentTaskPosition].buttonTask.label &&
                this.state.bot.taskInfo[this.state.currentTaskPosition].buttonTask.label.length > 0) {
                this.setState({ disableButtonTaskButton: false });
            }
            else {
                this.setState({ disableButtonTaskButton: true });
            }
        });
    }
    onClickSaveButtonDetails = () => {
        this.handleButtonHelperLabelModalShow(false);
        this.checkSyncingRequired(true);
    }
    handleEmailModalShow = (show, clearData, editing) => {
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearOneTaskData({ emailResultTask: { emailAddress: { $set: '' } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].emailResultTask.emailAddress : null },
                { ...this.state.bot },
                { emailModalShow: show, wasCreatedNewCloseHelper: false });
        } else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { emailModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { emailModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateOneTask(show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].emailResultTask.emailAddress,
                { ...this.state.bot },
                handleHideShowObject)
        }

    }
    handleEmailChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { emailResultTask: { emailAddress: { $set: e.target.value } } } })
            }
        }, () => {
            if (this.state.bot.taskInfo[this.state.currentTaskPosition].emailResultTask.emailAddress &&
                this.state.bot.taskInfo[this.state.currentTaskPosition].emailResultTask.emailAddress.length > 0) {
                this.setState({ disableEmailResultsButton: false });
            }
            else {
                this.setState({ disableEmailResultsButton: true });
            }
        });
    }
    onClickSaveEmailDetails = () => {
        this.handleEmailModalShow(false);
    }
    handleSmsModalShow = (show, clearData, editing) => {
        if (clearData && this.state.wasCreatedNewCloseHelper) {
            this.clearOneTaskData({ sMSResultsTask: { phoneNumber: { $set: '' } } },
                show,
                () => { return this.state.bot.taskInfo[this.state.currentTaskPosition] ? this.state.bot.taskInfo[this.state.currentTaskPosition].sMSResultsTask.phoneNumber : null },
                { ...this.state.bot },
                { smsModalShow: show, wasCreatedNewCloseHelper: false });
        }
        else {
            var handleHideShowObject = {};
            if (show && !editing) {
                handleHideShowObject =
                    { smsModalShow: show, wasCreatedNewCloseHelper: true }

            }
            else {
                handleHideShowObject =
                    { smsModalShow: show, wasCreatedNewCloseHelper: false }
            }
            this.validateOneTask(show,
                this.state.bot.taskInfo[this.state.currentTaskPosition].sMSResultsTask.phoneNumber,
                { ...this.state.bot },
                handleHideShowObject)
        }
    }
    clearOneTaskData = (taskObject, show, getLatestValueToValidate, bot, showTaskObject) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: taskObject })
            }
        },
            () => {
                this.validateOneTask(show, getLatestValueToValidate(), bot, showTaskObject)
            })
    }
    clearTwoTaskData = (taskObject, show, getLatestValueToValidate1, getLatestValueToValidate2, bot, showTaskObject) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: taskObject })
            }
        },
            () => {
                this.validateTwoTask(show, getLatestValueToValidate1(), getLatestValueToValidate2(), bot, showTaskObject)
            })
    }
    validateOneTask = (show, dataToValidate, bot, showTaskObject) => {
        if (!show) {
            if (!dataToValidate && dataToValidate.length < 1) {
                var bot = { ...this.state.bot }
                bot.taskInfo.splice(this.state.currentTaskPosition);
                if (bot.taskInfo.length > 0) {
                    this.setState({ bot, currentTaskPosition: bot.taskInfo.length - 1 }, () => {
                        this.setState(showTaskObject);
                    })
                }
                else {
                    this.setState({ bot }, () => {
                        this.setState(showTaskObject);
                    })
                }
            } else {
                this.setState(showTaskObject);
            }
        } else {
            this.setState(showTaskObject);
        }
    }
    validateTwoTask = (show, dataToValidate, dataToValidate2, bot, showTaskObject) => {
        if (Array.isArray(dataToValidate2)) {
            this.validateTwoTaskArray(show, dataToValidate, dataToValidate2, bot, showTaskObject);
        }
        else {
            if (!show) {
                if (!dataToValidate && dataToValidate.length < 1 && !dataToValidate2 && dataToValidate2.length < 1) {
                    var bot = { ...this.state.bot }
                    bot.taskInfo.splice(this.state.currentTaskPosition);
                    if (bot.taskInfo.length > 0) {
                        this.setState({ bot, currentTaskPosition: bot.taskInfo.length - 1 }, () => {
                            this.setState(showTaskObject);
                        })
                    }
                    else {
                        this.setState({ bot }, () => {
                            this.setState(showTaskObject);
                        })
                    }
                } else {
                    this.setState(showTaskObject);
                }
            } else {
                this.setState(showTaskObject);
            }
        }
    }
    validateTwoTaskArray = (show, dataToValidate, arrayDataToValidate2, bot, showTaskObject) => {
        if (!show) {
            if (!dataToValidate && dataToValidate.length < 1 && !arrayDataToValidate2[0] && arrayDataToValidate2[0].length < 1) {
                var bot = { ...this.state.bot }
                bot.taskInfo.splice(this.state.currentTaskPosition);
                if (bot.taskInfo.length > 0) {
                    this.setState({ bot, currentTaskPosition: bot.taskInfo.length - 1 }, () => {
                        this.setState(showTaskObject);
                    })
                }
                else {
                    this.setState({ bot }, () => {
                        this.setState(showTaskObject);
                    })
                }
            } else {
                this.setState(showTaskObject);
            }
        } else {
            this.setState(showTaskObject);
        }
    }
    handleSmsChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { sMSResultsTask: { phoneNumber: { $set: e.target.value } } } })
            }
        }, () => {
            if (this.state.bot.taskInfo[this.state.currentTaskPosition].sMSResultsTask.phoneNumber &&
                this.state.bot.taskInfo[this.state.currentTaskPosition].sMSResultsTask.phoneNumber.length > 0) {
                this.setState({ disableSmsResultsButton: false });
            }
            else {
                this.setState({ disableSmsResultsButton: true });
            }
        });
    }
    onClickSaveSmsDetails = () => {
        this.handleSmsModalShow(false);
    }
    modalFactory = (which, action) => {
        var bot = { ...this.state.bot }
        switch (action) {
            case 'login':
                this.setState({ loginTaskWasAdded: true });
                this.taskHelper(bot, { loginTask: { userName: '', password: '' } }, which)
                break;
            case 'input':
                if (this.state.loginTaskWasAdded && !this.state.bot.acceptedLoginConsent) {
                    this.handleTaskModalShow(false);
                    this.setState({ showLoginConsentWarning: true, afterLoginConsentAccepted: () => { this.taskHelper(bot, { textFieldTask: { inputValue: '', label: '' } }, which) } })
                }
                else
                    this.taskHelper(bot, { textFieldTask: { inputValue: '', label: '' } }, which)
                break;
            case 'button':
                if (this.state.loginTaskWasAdded && !this.state.bot.acceptedLoginConsent) {
                    this.handleTaskModalShow(false);
                    this.setState({ showLoginConsentWarning: true, afterLoginConsentAccepted: () => { this.taskHelper(bot, { buttonTask: { label: '' } }, which) } })
                }
                else
                    this.taskHelper(bot, { buttonTask: { label: '' } }, which)
                break;
            case 'select':
                if (this.state.loginTaskWasAdded && !this.state.bot.acceptedLoginConsent) {
                    this.handleTaskModalShow(false);
                    this.setState({ showLoginConsentWarning: true, afterLoginConsentAccepted: () => { this.taskHelper(bot, { selectTask: { option: '', label: '' } }, which) } })
                }
                else
                    this.taskHelper(bot, { selectTask: { option: '', label: '' } }, which)
                break;
            case 'datePicker':
                if (this.state.loginTaskWasAdded && !this.state.bot.acceptedLoginConsent) {
                    this.handleTaskModalShow(false);
                    this.setState({ showLoginConsentWarning: true, afterLoginConsentAccepted: () => { this.taskHelper(bot, { datePickerTask: { option: '', label: '', daysAutoIncreaseDateValue: 'None' } }, which) } })
                }
                else
                    this.taskHelper(bot, { datePickerTask: { option: '', label: '', daysAutoIncreaseDateValue: 'None' } }, which)
                break;
            case 'listSelector':
                if (this.state.loginTaskWasAdded && !this.state.bot.acceptedLoginConsent) {
                    this.handleTaskModalShow(false);
                    this.setState({ showLoginConsentWarning: true, afterLoginConsentAccepted: () => { this.taskHelper(bot, { listSelectorTask: { options: [{ compareText: '', exactMatch: false }], label: '' } }, which) } })
                }
                else
                    this.taskHelper(bot, { listSelectorTask: { options: [{ compareText: '', exactMatch: false }], label: '' } }, which)
                break;
            case 'email':
                this.taskHelper(bot, { emailResultTask: { emailAddress: '' } }, which)
                break;
            case 'sms':
                this.taskHelper(bot, { sMSResultsTask: { phoneNumber: '' } }, which)
                break;
        }
    }
    taskHelper = (bot, newTask, which) => {
        bot.taskInfo.push(newTask);
        this.setState({ bot, currentTaskPosition: bot.taskInfo.length - 1 }, () => {
            this.handleTaskModalShow(false);
            which(true);
        });
    }
    onTaskDragStart = () => {
        this.setState({ hideAssignButton: true, dragInProgress: true });
    }
    onTaskDragEnd = (result) => {
        this.setState({ hideAssignButton: false, dragInProgress: false });
        if (result.destination) {
            const taskInfo = reorder(
                this.state.bot.taskInfo,
                result.source.index,
                result.destination.index
            );
            this.setState({ bot: { ...this.state.bot, taskInfo: taskInfo } }, () => {
                if (this.state.injectMode) {
                    var taskItem = this.state.bot.taskInfo[result.destination.index];
                    this.setState({ injectModelPlacement: result.destination.index, injectedTask: taskItem }, () => this.showInjectModal(true))
                }
                else
                    this.checkSyncingRequired()
            });
        } else {
            var taskItem = this.state.bot.taskInfo[result.source.index];
            var deleteMessage = '';
            if (taskItem) {
                if (taskItem.loginTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " login"
                }
                else if (taskItem.buttonTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " button"
                }
                else if (taskItem.textFieldTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " input"
                }
                else if (taskItem.selectTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " select"
                }
                else if (taskItem.datePickerTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " date picker"
                }
                else if (taskItem.listSelectorTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " list picker"
                }
                else if (taskItem.sMSResultsTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " text result"
                }
                else if (taskItem.emailResultTask) {
                    deleteMessage = "step " + (result.source.index + 1) + " email result"
                }
            }
            var deleteItem = { message: deleteMessage, index: result.source.index }
            this.setState({ confirmDeleteModalShow: true, pendingDeleteTask: deleteItem });
        }
    }
    handleConfirmDeleteShow = (show) => {
        this.setState({ confirmDeleteModalShow: show });
    }
    handleShowLoginConsentWarning = (show) => {
        this.setState({ showLoginConsentWarning: show });
    }
    onAllowConsentClick = () => {
        this.setState({ showLoginConsentWarning: false }, () => {
            this.state.afterLoginConsentAccepted();
            this.setState({ afterLoginConsentAccepted: undefined, bot: { ...this.state.bot, acceptedLoginConsent: true } })
        });
    }
    onDoNotAllowConsentClick = () => {
        this.handleShowLoginConsentWarning(false);
    }
    handleShowUpdateRedirectUrl = (show, hide) => {
        if (hide) hide();
        this.setState({ showUpdateRedirectUrlModalShow: show });
    }
    handleShowAdminPanelModal = (show) => {
        this.setState({ adminPanelModalShow: show });
    }
    handleOnRedirectUrlChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, taskInfo:
                    update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { redirectUrl: { url: { $set: e.target.value } } } })
            }
        }, () => { })
    }
    onClickSaveHtmlControlRedirectUrl = () => {
        this.setState({ showUpdateRedirectUrlModalShow: false });
        ApiClient.post('bot/update/redirectUrl', { botId: this.state.bot.botId, htmlControlId: this.state.bot.taskInfo[this.state.currentTaskPosition].redirectUrl.htmlControlId, url: this.state.bot.taskInfo[this.state.currentTaskPosition].redirectUrl.url }).then((response) => {

        }).catch((error) => {
            alert(JSON.stringify(error.response.data));

        });
    }
    onClickConfirmDeleteTask = () => {
        var bot = { ...this.state.bot }
        var taskRemoved = bot.taskInfo[this.state.pendingDeleteTask.index];
        if (taskRemoved && (taskRemoved.buttonTask || taskRemoved.textFieldTask || taskRemoved.selectTask || taskRemoved.listSelectorTask)) {
            this.setState({ pendingTaskRemove: taskRemoved })
        }
        bot.taskInfo.splice(this.state.pendingDeleteTask.index, 1);
        if (bot.taskInfo.length > 0) {
            this.setState({ bot, currentTaskPosition: bot.taskInfo.length - 1 }, () => {
                this.setState({ confirmDeleteModalShow: false }, () => {
                    this.checkSyncingRequired();
                });
            })
        }
        else {
            this.setState({ bot }, () => {
                this.setState({ confirmDeleteModalShow: false }, () => { this.checkSyncingRequired(); });
            })
        }
    }
    onClickDeleteTask = (index) => {
        var taskItem = this.state.bot.taskInfo[index];
        var deleteMessage = '';
        if (taskItem) {
            if (taskItem.loginTask) {
                deleteMessage = "step " + (index + 1) + " login"
            }
            else if (taskItem.buttonTask) {
                deleteMessage = "step " + (index + 1) + " button"
            }
            else if (taskItem.textFieldTask) {
                deleteMessage = "step " + (index + 1) + " input"
            }
            else if (taskItem.selectTask) {
                deleteMessage = "step " + (index + 1) + " select"
            }
            else if (taskItem.datePickerTask) {
                deleteMessage = "step " + (index + 1) + " date picker"
            }
            else if (taskItem.listSelectorTask) {
                deleteMessage = "step " + (index + 1) + " list picker"
            }
            else if (taskItem.sMSResultsTask) {
                deleteMessage = "step " + (index + 1) + " text result"
            }
            else if (taskItem.emailResultTask) {
                deleteMessage = "step " + (index + 1) + " email result"
            }
        }
        var deleteItem = { message: deleteMessage, index: index }
        this.setState({ confirmDeleteModalShow: true, pendingDeleteTask: deleteItem });
    }
    onClickEditTask = (index) => {
        var task = this.state.bot.taskInfo[index];
        if (task.loginTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleLoginModalShow(true, false, true);
            });
        }
        else if (task.buttonTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleButtonHelperLabelModalShow(true, false, true);
            });
        }
        else if (task.textFieldTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleInputHelperLabelModalShow(true, false, true);
            });
        }
        else if (task.selectTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleSelectOptionHelperLabelModalShow(true, false, true);
            });
        }
        else if (task.datePickerTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleDatePickerModalShow(true, false, true);
            });
        }
        else if (task.listSelectorTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleListSelectorModalShow(true, false, true);
            });
        }
        else if (task.emailResultTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleEmailModalShow(true, false, true);
            });
        }
        else if (task.sMSResultsTask) {
            this.setState({ currentTaskPosition: index }, () => {
                this.handleSmsModalShow(true, false, true);
            });
        }

    }
    handleAddTimeModalShow = (show, createNew, removeItem) => {
        if (createNew) {
            var bot = { ...this.state.bot }
            bot.runTimes.push({ botRunDays: '', scheduledTime: moment().hour(0).minute(0).second(0).format('hh:mm:ss'), localTimeZoneId: '' });
            this.setState({ bot, currentSchedulePosition: bot.runTimes.length - 1, wasCreatedNewCloseHelper: true }, () => {
                this.setState({ addScheduleModalShow: show });
            });
        } else {
            if (removeItem && this.state.wasCreatedNewCloseHelper) {
                var bot = { ...this.state.bot }
                bot.runTimes.splice(this.state.currentSchedulePosition);
                if (bot.runTimes.length > 0) {
                    this.setState({ bot, currentSchedulePosition: bot.runTimes.length - 1, wasCreatedNewCloseHelper: false }, () => {
                        this.setState({ addScheduleModalShow: show });
                    })
                } else {
                    this.setState({ addScheduleModalShow: show });
                }
            } else { this.setState({ addScheduleModalShow: show }); }
        }

    }
    handleMinuteBurstChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, runTimes:
                    update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { minuteBurst: { $set: e.target.checked } } })
            }
        }, () => {
            this.validateScheduleData()
        });
    }
    handleInfiniteRunChange = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, runTimes:
                    update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { infiniteRun: { $set: e.target.checked } } })
            }
        }, () => {
            this.validateScheduleData()
        });
    }
    handleOnTimeZoneSelect = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, runTimes:
                    update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { localTimeZoneId: { $set: e.target.value } } })
            }
        }, () => {
            this.validateScheduleData()
        });
    }
    handleCustomRedirect = (e) => {
        this.setState({ bot: { ...this.state.bot, allowCustomRedirectUrl: e.target.checked } });
    }
    handleForceScheduleReset = (e) => {
        this.setState({ bot: { ...this.state.bot, forceScheduleReset: e.target.checked } });
    }
    handleRunLocallyOption = (e) => {
        this.setState({ bot: { ...this.state.bot, runLocally: e.target.checked } });
    }
    handleHideFailedResults = (e) => {
        this.setState({ bot: { ...this.state.bot, hideFailedResults: e.target.checked } });
    }
    handleSkipRunTimeStep = (e) => {
        this.setState({ bot: { ...this.state.bot, skipRunTimeStep: e.target.checked } });
    }
    handleSkipIpSetup = (e) => {
        this.setState({ bot: { ...this.state.bot, skipIPSetup: e.target.checked } });
    }
    handleSkipDateInCreaseIfFail = (e) => {
        this.setState({ bot: { ...this.state.bot, skipDateInCreaseIfFail: e.target.checked } });
    }
    handlePauseAfterSuccess = (e) => {
        this.setState({ bot: { ...this.state.bot, pauseAfterSuccess: e.target.checked } });
    }
    handleApiUseOnly = (e) => {
        this.setState({ bot: { ...this.state.bot, apiUseOnly: e.target.checked } });
    }
    handleTrackLastRunStatus = (e) => {
        this.setState({ bot: { ...this.state.bot, trackLastRunStatus: e.target.checked } });
    }
    handleLurkStart = (e) => {
        this.setState({ bot: { ...this.state.bot, startLurk: e.target.checked } });
    }
    handleIsSpreadSheetBot = (e) => {
        this.setState({ bot: { ...this.state.bot, isSpreadSheetBot: e.target.checked } });
    }
    handleExtendPageLoadTimeout = (e) => {
        this.setState({ bot: { ...this.state.bot, extendPageLoadTimeout: e.target.checked } });
    }
    handleSendNoResultsEmail = (e) => {
        this.setState({ bot: { ...this.state.bot, sendNoResultsEmail: e.target.checked } });
    }
    handleContinueAfterNoResults = (e) => {
        this.setState({ bot: { ...this.state.bot, continueAfterNoResults: e.target.checked } });
    }
    handleDataCenterLocationChange = (e) => {
        this.setState({ bot: { ...this.state.bot, dataCenterLocation: e.target.value } });
    }
    handleIPSettingChange = (e) => {
        this.setState({ bot: { ...this.state.bot, iPSetting: e.target.value } });
    }
    handleDriveHubChange = (e) => {
        this.setState({ bot: { ...this.state.bot, driverHub: e.target.value } });
    }
    handleProxyGroupChange = (e) => {
        this.setState({ bot: { ...this.state.bot, proxyGroup: e.target.value } });
    }
    handleChromeExtensionChange = (e) => {
        this.setState({ bot: { ...this.state.bot, chromeExtensionType: e.target.value } });
    }
    handleBotDurationChange = (e) => {
        this.setState({ bot: { ...this.state.bot, burstDuration: e.target.value ? parseInt(e.target.value) : null } });
    }
    handleOffSetScheduleTimeChange = (e) => {
        this.setState({ bot: { ...this.state.bot, offSetScheduleTime: parseInt(e.target.value) } });
    }
    handleBurstCountChange = (e) => {
        this.setState({ bot: { ...this.state.bot, burstCount: parseInt(e.target.value) } });
    }
    handleMainBotStartDelayChange = (e) => {
        this.setState({ bot: { ...this.state.bot, mainBotStartDelay: parseInt(e.target.value) } });
    }
    handleCustomFailCountChange = (e) => {
        this.setState({ bot: { ...this.state.bot, customFailCount: parseInt(e.target.value) } });
    }
    handleLatestFailRunElapseChange = (e) => {
        this.setState({ bot: { ...this.state.bot, latestFailRunElapse: parseInt(e.target.value) } });
    }
    handleMainBotTransferOwnership = (e) => {
        this.setState({ bot: { ...this.state.bot, userId: e.target.value } });
    }
    handleMiniBotChange = (e) => {
        this.setState({ bot: { ...this.state.bot, miniBotId: e.target.value } });
    }
    handleTabRecordingWebsiteTitle = (e) => {
        this.setState({ bot: { ...this.state.bot, tabRecorderWebsiteTitle: e.target.value } });
    }
    handleBrowserProfileId = (e) => {
        this.setState({ bot: { ...this.state.bot, browserProfileId: e.target.value } });
    }
    handleNaturalScreenResolution = (e) => {
        this.setState({ bot: { ...this.state.bot, naturalScreenResolution: e.target.checked } });
    }
    handleDisableStealthProxy = (e) => {
        this.setState({ bot: { ...this.state.bot, disableStealthProxy: e.target.checked } });
    }
    handleChainBotIdChange = (e) => {
        this.setState({ bot: { ...this.state.bot, chainBotId: e.target.value } });
    }
    handleChainBotParamChange = (e) => {
        this.setState({ bot: { ...this.state.bot, chainBotParams: e.target.value } });
    }
    handleMainBotStaticIPChange = (e) => {
        this.setState({ bot: { ...this.state.bot, staticIP: e.target.value } });
    }
    handleDataFriendlyMessageKeysChange = (e) => {
        this.setState({ bot: { ...this.state.bot, dataFriendlyMessageKeys: e.target.value } });
    }
    handleWindowIndexMultiplierChange = (e) => {
        this.setState({ bot: { ...this.state.bot, windowIndexMultiplier: parseInt(e.target.value) } });
    }
    handleMaxDelayCountChange = (e) => {
        this.setState({ bot: { ...this.state.bot, maxDelayCount: parseInt(e.target.value) } });
    }
    handleBotCountryChange = (e) => {
        this.setState({ bot: { ...this.state.bot, country: e.target.value } });
    }
    handleOnCheckScheduleChange = (e) => {
        var currentValue = this.state.bot.runTimes[this.state.currentSchedulePosition].botRunDays;
        var doesEndWithComma = /^,|,$/g.test(currentValue)
        switch (e.target.getAttribute("id")) {
            case 'chkSun':
                var beforeExist = this.dayContainsComma(currentValue, ',SUNDAY');
                var afterExist = this.dayContainsComma(currentValue, 'SUNDAY,');
                this.updateBotRunDays(currentValue, e.target.checked, doesEndWithComma ? 'SUNDAY,' : ',SUNDAY,', afterExist ? /SUNDAY,/gi : beforeExist ? /,SUNDAY/gi : /SUNDAY/gi);
                break;
            case 'chkMon':
                var beforeExist = this.dayContainsComma(currentValue, ',MONDAY');
                var afterExist = this.dayContainsComma(currentValue, 'MONDAY,');
                this.updateBotRunDays(currentValue, e.target.checked, doesEndWithComma ? 'MONDAY,' : ',MONDAY,', afterExist ? /MONDAY,/gi : beforeExist ? /,MONDAY/gi : /MONDAY/gi);
                break;
            case 'chkTues':
                var beforeExist = this.dayContainsComma(currentValue, ',TUESDAY');
                var afterExist = this.dayContainsComma(currentValue, 'TUESDAY,');
                this.updateBotRunDays(currentValue, e.target.checked, doesEndWithComma ? 'TUESDAY,' : ',TUESDAY,', afterExist ? /TUESDAY,/gi : beforeExist ? /,TUESDAY/gi : /TUESDAY/gi);
                break;
            case 'chkWed':
                var beforeExist = this.dayContainsComma(currentValue, ',WEDNESDAY');
                var afterExist = this.dayContainsComma(currentValue, 'WEDNESDAY,');
                this.updateBotRunDays(currentValue, e.target.checked, doesEndWithComma ? 'WEDNESDAY,' : ',WEDNESDAY,', afterExist ? /WEDNESDAY,/gi : beforeExist ? /,WEDNESDAY/gi : /WEDNESDAY/gi);
                break;
            case 'chkThurs':
                var beforeExist = this.dayContainsComma(currentValue, ',THURSDAY');
                var afterExist = this.dayContainsComma(currentValue, 'THURSDAY,');
                this.updateBotRunDays(currentValue, e.target.checked, doesEndWithComma ? 'THURSDAY,' : ',THURSDAY,', afterExist ? /THURSDAY,/gi : beforeExist ? /,THURSDAY/gi : /THURSDAY/gi);
                break;
            case 'chkFri':
                var beforeExist = this.dayContainsComma(currentValue, ',FRIDAY');
                var afterExist = this.dayContainsComma(currentValue, 'FRIDAY,');
                this.updateBotRunDays(currentValue, e.target.checked, doesEndWithComma ? 'FRIDAY,' : ',FRIDAY,', afterExist ? /FRIDAY,/gi : beforeExist ? /,FRIDAY/gi : /FRIDAY/gi);
                break;
            case 'chkSat':
                var beforeExist = this.dayContainsComma(currentValue, ',SATURDAY');
                var afterExist = this.dayContainsComma(currentValue, 'SATURDAY,');
                this.updateBotRunDays(currentValue, e.target.checked, doesEndWithComma ? 'SATURDAY,' : ',SATURDAY,', afterExist ? /,SATURDAY,/gi : beforeExist ? /,SATURDAY/gi : /SATURDAY/gi);
                break;
        }
    }
    dayContainsComma = (currentAllDaysSelected, day) => {
        if (currentAllDaysSelected.indexOf(day) == -1) {
            return false
        }
        return true;
    }

    updateBotRunDays = (currentValue, isChecked, whichDay, replaceEx) => {

        if (isChecked) {
            this.setState({
                bot: {
                    ...this.state.bot, runTimes:
                        update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { botRunDays: { $set: currentValue += whichDay } } })
                }
            }, () => { this.validateScheduleData() })
        }
        else {
            if (this.state.bot.runTimes[this.state.currentSchedulePosition].botRunDays) {
                this.setState({
                    bot: {
                        ...this.state.bot, runTimes:
                            update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { botRunDays: { $set: currentValue.replace(replaceEx, '') } } })
                    }
                }, () => { this.validateScheduleData() })
            }
        }
    }
    onScheduleValueChange = (value) => {
        if (value && value.format('HH:mm:ss').length > 0) {
            this.setState({
                bot: {
                    ...this.state.bot, runTimes:
                        update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { scheduledTime: { $set: value.format('HH:mm:ss') }, frequency: { $set: null }, frequencyWindow: { $set: false } } })
                }
            }, () => { this.validateScheduleData(); })
        }
        else {
            this.validateScheduleData();
        }
    }
    handleOnCheckFrequencyWindow = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, runTimes:
                    update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { frequencyWindow: { $set: e.target.checked }, frequencyWindowStart: { $set: null }, frequencyWindowEnd: { $set: null }, runOnlineHoursOnly: { $set: false }, runOfflineHoursOnly: { $set: false } } })
            }
        }, () => { })
    }
    handleOnCheckRunOnlineHoursOnly = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, runTimes:
                    update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { runOnlineHoursOnly: { $set: e.target.checked }, runOfflineHoursOnly: { $set: false } } })
            }
        }, () => { })
    }
    handleOnCheckRunOfflineHoursOnly = (e) => {
        this.setState({
            bot: {
                ...this.state.bot, runTimes:
                    update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { runOfflineHoursOnly: { $set: e.target.checked }, runOnlineHoursOnly: { $set: false } } })
            }
        }, () => { })
    }
    onWindowStartHour = (value) => {
        if (value && value.format('HH:mm').length > 0) {
            this.setState({
                bot: {
                    ...this.state.bot, runTimes:
                        update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { frequencyWindowStart: { $set: value.format('HH:mm') } } })
                }
            }, () => { })
        }
    }
    onWindowEndHour = (value) => {
        if (value && value.format('HH:mm').length > 0) {
            this.setState({
                bot: {
                    ...this.state.bot, runTimes:
                        update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { frequencyWindowEnd: { $set: value.format('HH:mm') } } })
                }
            }, () => { })
        }
    }
    onFrequencyValueChange = (value) => {
        if (value) {
            if (this.invalidSubscriptionForFrequency(value)) {
                this.setState({ hideFrequencyErrorMessage: false });
                return;
            } else {
                this.setState({
                    hideFrequencyErrorMessage: true,
                    bot: {
                        ...this.state.bot, runTimes:
                            update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { frequency: { $set: value }, scheduledTime: { $set: null } } })
                    }
                }, () => { this.validateScheduleData(); })
            }
        }
        else {
            this.validateScheduleData();
        }
    }
    validateScheduleData = () => {
        if (this.state.bot.runTimes[this.state.currentSchedulePosition].localTimeZoneId &&
            this.state.bot.runTimes[this.state.currentSchedulePosition].localTimeZoneId.length > 0 &&
            this.state.bot.runTimes[this.state.currentSchedulePosition].botRunDays &&
            this.state.bot.runTimes[this.state.currentSchedulePosition].botRunDays.length > 0 &&
            this.state.bot.runTimes[this.state.currentSchedulePosition].botRunDays != "," &&
            ((this.state.bot.runTimes[this.state.currentSchedulePosition].frequency && this.state.bot.runTimes[this.state.currentSchedulePosition].frequency) ||
                (this.state.bot.runTimes[this.state.currentSchedulePosition].scheduledTime &&
                    this.state.bot.runTimes[this.state.currentSchedulePosition].scheduledTime.length > 0))) {
            this.setState({ disableScheduleButton: false, hideFrequencyErrorMessage: true })
        } else {
            this.setState({ disableScheduleButton: true })
        }
    }
    onClickSaveNewSchedule = () => {
        var days = this.state.bot.runTimes[this.state.currentSchedulePosition].botRunDays;
        this.setState({
            wasCreatedNewCloseHelper: false,
            bot: {
                ...this.state.bot, runTimes:
                    update(this.state.bot.runTimes, { [this.state.currentSchedulePosition]: { botRunDays: { $set: days.replace(/^,|,$/g, '') } } })
            }
        }, () => { this.handleAddTimeModalShow(false, false); });
    }
    onScheduleDragStart = () => {
        this.setState({ hideAddTimeButton: true, dragInProgressSchedule: true });
    }
    onScheduleDragEnd = (result) => {
        this.setState({ hideAddTimeButton: false, dragInProgressSchedule: false });
        if (this.state.bot.containBurst && !this.state.hasAccess) {
            return;
        }
        if (result.destination) {
            const runTimes = reorder(
                this.state.bot.runTimes,
                result.source.index,
                result.destination.index
            );
            this.setState({ bot: { ...this.state.bot, runTimes: runTimes } });
        } else {
            var runTime = this.state.bot.runTimes[result.source.index];
            var deleteMessage = '';
            if (runTime) {
                if (runTime.scheduledTime)
                    deleteMessage = "Schedule item " + (result.source.index + 1) + " for " + runTime.scheduledTime;
                else if (runTime.frequency)
                    deleteMessage = "Schedule item " + (result.source.index + 1) + " for every " + runTime.frequency;

            }
            var deleteItem = { message: deleteMessage, index: result.source.index }
            this.setState({ confirmDeleteScheduleModalShow: true, pendingDeleteSchedule: deleteItem });
        }
    }
    handleConfirmDeleteScheduleShow = (show) => {
        this.setState({ confirmDeleteScheduleModalShow: show });
    }
    onDeleteScheduleItem = (index) => {
        var runTime = this.state.bot.runTimes[index];
        var deleteMessage = '';
        if (runTime) {
            if (runTime.scheduledTime)
                deleteMessage = "Schedule item " + (index + 1) + " for " + runTime.scheduledTime;
            else if (runTime.frequency)
                deleteMessage = "Schedule item " + (index + 1) + " for every " + runTime.frequency;
        }
        var deleteItem = { message: deleteMessage, index: index }
        this.setState({ confirmDeleteScheduleModalShow: true, pendingDeleteSchedule: deleteItem });
    }
    onClickConfirmDeleteSchedule = () => {
        var bot = { ...this.state.bot }
        bot.runTimes.splice(this.state.pendingDeleteSchedule.index, 1);
        if (bot.runTimes.length > 0) {
            this.setState({ bot, currentSchedulePosition: bot.runTimes.length - 1 }, () => {
                this.setState({ confirmDeleteScheduleModalShow: false });
            })
        }
        else {
            this.setState({ bot }, () => {
                this.setState({ confirmDeleteScheduleModalShow: false });
            })
        }
    }
    onClickEditSchedule = (index) => {
        this.setState({ currentSchedulePosition: index }, () => { this.handleAddTimeModalShow(true, false); this.validateScheduleData() });

    }
    onClickStepBack = () => {
        var currentStep = this.state.step;
        this.setState({ step: currentStep - 1 })
    }
    onClickStepForward = () => {
        var currentStep = this.state.step;
        if (currentStep == 3 && this.state.bot.skipRunTimeStep && !this.state.hasAccess)
            this.onClickSaveBotData();
        else
            this.setState({ step: currentStep + 1 })
    }
    onClickSaveBotData = () => {
        this.setState({ disableSaveButton: true, saveButtonText: 'Saving...' }, () => {
            ApiClient.post('bot/create', { ...this.state.bot }).then((response) => {
                this.setState({ showAnimation: true }, () => {
                    this.setState({ saveButtonText: 'Save', disableSaveButton: false, isBotComplete: response.data.isActive }, () => {
                        this.handleFinishModalShow(true)
                    });
                });
            }).catch((error) => {
                alert(JSON.stringify(error.response.data));
                this.setState({ saveButtonText: 'Save', disableSaveButton: false });
            });
        });


    }
    handleFinishModalShow = (show) => {
        this.setState({ finishCreateBotModalShow: show })
    }
    showStepUpgradeButton = () => {
        if (this.state.subscriptionName == "Starter") {
            if (this.state.bot && this.state.bot.taskInfo && this.state.bot.taskInfo.length == 1) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.state.subscriptionName == "Basic") {
            if (this.state.bot && this.state.bot.taskInfo && this.state.bot.taskInfo.length == 5) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.state.subscriptionName == "Standard") {
            if (this.state.bot && this.state.bot.taskInfo && this.state.bot.taskInfo.length == 20) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.state.subscriptionName == "Pro") {
            if (this.state.bot && this.state.bot.taskInfo && this.state.bot.taskInfo.length == 30) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.state.subscriptionName == "Elite") {
            if (this.state.bot && this.state.bot.taskInfo && this.state.bot.taskInfo.length == 50) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    showRunTimesUpgradeButton = () => {
        if (this.state.subscriptionName == "Starter") {
            if (this.state.bot && this.state.bot.runTimes && this.state.bot.runTimes.length == 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.state.subscriptionName == "Basic" || this.state.subscriptionName == "Standard" || this.state.subscriptionName == "Pro") {
            if (this.state.bot && this.state.bot.runTimes && this.state.bot.runTimes.length == 1) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.state.subscriptionName == "Pro Plus" || this.state.subscriptionName == "Standard Plus" || this.state.subscriptionName == "Elite") {
            if (this.state.bot && this.state.bot.runTimes && this.state.bot.runTimes.length == 2) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    handleBuildMyOwn = () => {
        this.setState({ isLoading: false, step: 1 }, () => { this.handleShowingUrlHelperModalFromClip(); })
    }
    startSeries = (seriesId, name, exampleBotName, questionType) => {
        if (!this.state.isLoading) this.showIsLoading(true);
        ApiClient.get('minibot/' + questionType + '/list').then((responseMiniBots) => {
            switch (questionType) {
                case 0://Golf
                    this.setState({ supportedGolfCourses: responseMiniBots.data.miniBots }, () => {
                        ApiClient.get('bot/series/' + seriesId).then((response) => {
                            this.setState({ currentSeriesId: seriesId, isLoading: false, series: response.data, seriesTitle: name, exampleBotName: exampleBotName, miniBotSearchType: questionType }, () => this.handleHideSeriesQuestionnaire(true));
                        }).catch((error) => { });
                    });
                    break;
                case 1://Tennis
                    this.setState({ supportedTennisCourts: responseMiniBots.data.miniBots }, () => {
                        ApiClient.get('bot/series/' + seriesId).then((response) => {
                            this.setState({ currentSeriesId: seriesId, isLoading: false, series: response.data, seriesTitle: name, exampleBotName: exampleBotName, miniBotSearchType: questionType }, () => this.handleHideSeriesQuestionnaire(true));
                        }).catch((error) => { });
                    });
                    break;
                case 2://Pickleball
                    this.setState({ supportedPickleBallCourts: responseMiniBots.data.miniBots }, () => {
                        ApiClient.get('bot/series/' + seriesId).then((response) => {
                            this.setState({ currentSeriesId: seriesId, isLoading: false, series: response.data, seriesTitle: name, exampleBotName: exampleBotName, miniBotSearchType: questionType }, () => this.handleHideSeriesQuestionnaire(true));
                        }).catch((error) => { });
                    });
                    break;
                case 3://Camping
                    this.setState({ supportedCampingSites: responseMiniBots.data.miniBots }, () => {
                        ApiClient.get('bot/series/' + seriesId).then((response) => {
                            this.setState({ currentSeriesId: seriesId, isLoading: false, series: response.data, seriesTitle: name, exampleBotName: exampleBotName, miniBotSearchType: questionType }, () => this.handleHideSeriesQuestionnaire(true));
                        }).catch((error) => { });
                    });
                    break;
                case 4://Dinner
                    this.setState({ supportedDinnerSites: responseMiniBots.data.miniBots }, () => {
                        ApiClient.get('bot/series/' + seriesId).then((response) => {
                            this.setState({ currentSeriesId: seriesId, isLoading: false, series: response.data, seriesTitle: name, exampleBotName: exampleBotName, miniBotSearchType: questionType }, () => this.handleHideSeriesQuestionnaire(true));
                        }).catch((error) => { });
                    });
                    break;
                default:
                    ApiClient.get('bot/series/' + seriesId).then((response) => {
                        this.setState({ currentSeriesId: seriesId, isLoading: false, series: response.data, seriesTitle: name, exampleBotName: exampleBotName, miniBotSearchType: questionType }, () => this.handleHideSeriesQuestionnaire(true));
                    }).catch((error) => { });
                    break;
            }
        }).catch((error) => { });

    }
    navigateQuestionnaire = (step, type, isRepeaterSetup, isPositiveClick, isNegativeClick, isNeutralAvailable, isStepPickerClick, positiveHref, negativeHref, neutralHref) => (e) => {
        if (type && type == "GOSUBSCRIPTION") {
            if (this.state.subCustomer) {
                if (step == 0 && !positiveHref && !negativeHref && !neutralHref) {
                    window.location = '/subscription?sub=sp';
                    return;
                }
                else if (positiveHref) {
                    ReactPixel.track('InitiateCheckout', { currency: "USD", value: 9.99, content_type: 'standard_subscription' })
                    TiktokPixel.track('InitiateCheckout', { value: 9.99, content_type: 'standard_subscription', content_name: 'Template Standard Subscription', currency: 'USD' });
                    window.location = positiveHref;
                    return;
                }
                else if (negativeHref) {
                    ReactPixel.track('InitiateCheckout', { currency: "USD", value: 99.99, content_type: 'pro_subscription' })
                    TiktokPixel.track('InitiateCheckout', { value: 99.99, content_type: 'pro_subscription', content_name: 'Template Pro Subscription', currency: 'USD' });
                    window.location = negativeHref;
                    return;
                }
                else if (neutralHref) {
                    window.location = neutralHref;
                    return;
                }
            }
        }
        if (type && type == "GOHOME") {
            const series = [...this.state.series];
            if (series) {
                let feedbackDetails = series.filter(function (item) {
                    return item && item.answer && item.answer.questionType == "FEEDBACKMESSAGE";
                }).map(function (item) {
                    return item.answer.answer1;
                });
                let miniBotAdvanceDayPicker = series.filter(function (item) {
                    return item && item.answer && item.answer.questionType == "MINIBOTADVANCEDAYPICKER";
                }).map(function (item) {
                    return item.answer.answer1;
                });
                let courseId = series.filter(function (item) {
                    return item && item.answer && item.answer.questionType == "SHARETEETIMEBOTLOOKUP";
                }).map(function (item) {
                    return item.answer.answer1;
                });
                if (miniBotAdvanceDayPicker && miniBotAdvanceDayPicker.length > 0)
                    miniBotAdvanceDayPicker = miniBotAdvanceDayPicker.filter(function (el) {
                        return el != null;
                    });
                var teeTimeRanger = this.state.miniBotFinalTag;
                if (miniBotAdvanceDayPicker && miniBotAdvanceDayPicker.length > 0 && courseId && courseId.length > 0 && teeTimeRanger) {
                    ApiClient.post('bot/submit/minibot', { botRunDays: miniBotAdvanceDayPicker[0], sharedBotId: courseId[0], compareText: teeTimeRanger, feedbackDetails: feedbackDetails[0] }).then((response) => {
                        if (response.status == 200) window.location = "/";

                    }).catch((error) => {
                        alert(JSON.stringify(error.response.data));

                    });
                } else alert("Error setting up Bot. Please setup using the manual process")
            }
            else alert("Error setting up Bot. Please setup using the manual process")
            return;
        }
        if ((this.state.currentQuestionIterator + 1) >= this.state.series.length) {
            this.submitAnswers();
            return;
        }
        if ((this.state.currentQuestionIterator + step) >= this.state.series.length) {
            this.submitAnswers();
            return;
        }
        var counter = this.state.currentQuestionIterator + step;

        if (isRepeaterSetup && !this.state.inPositiveRepeatMode && !this.state.inNegativeRepeatMode && !this.state.inStepPickerMode) {
            this.setState({ repeaterQuestionIndex: this.state.currentQuestionIterator });
        }
        if (isRepeaterSetup && (this.state.inPositiveRepeatMode || this.state.inNegativeRepeatMode || this.state.inStepPickerMode) &&
            this.state.repeaterQuestionIndex != this.state.currentQuestionIterator) {

            this.setState({ positiveRepeatCount: 0, negativeRepeatCount: 0, stepPickerRepeatCount: 0, repeaterQuestionIndex: this.state.currentQuestionIterator })
        }
        if ((this.state.inPositiveRepeatMode || this.state.inNegativeRepeatMode || this.state.inStepPickerMode) && !isRepeaterSetup && step > -1) {

            this.setState({ positiveRepeatCount: 0, negativeRepeatCount: 0, stepPickerRepeatCount: 0, inPositiveRepeatMode: false, inNegativeRepeatMode: false, inStepPickerMode: false });
        }
        if (isRepeaterSetup && step > 0 && isPositiveClick) {
            this.setState({ inPositiveRepeatMode: true, inNegativeRepeatMode: false, inStepPickerMode: false });
        }
        if (isRepeaterSetup && step > 0 && isNegativeClick && isNeutralAvailable) {
            this.setState({ inNegativeRepeatMode: true, inPositiveRepeatMode: false, inStepPickerMode: false });
        }
        if (isRepeaterSetup && step > 0 && isStepPickerClick) {
            this.setState({ inStepPickerMode: true, inPositiveRepeatMode: false, inNegativeRepeatMode: false });
        }

        if (type && type == "URL") {
            // var siteUrl = 'bot/unsupported/site?url=' + encodeURIComponent(this.state.series[this.state.currentQuestionIterator].answer.answer1);
            // ApiClient.get(siteUrl).then((response) => {
            //     if (!response.data) {
            this.setState({
                currentQuestionIterator: counter,
                previousStepCounter: step, isAnswerButtonDisabled: true,
                hideErrorMessage: true, errorMessageText: ''
            }, () => { this.increaseMasterOrderCount(); this.postNavigationAction() })
            //}
            //     else {
            //         var site = this.state.series[this.state.currentQuestionIterator].answer.answer1;
            //         this.setState({ hideErrorMessage: false, errorMessageText: site + ' not supported. Please try another website.' })
            //     }
            // }).catch((error) => {
            // });
        } else if (type && type == "LOGINCONSENTAGREED") {
            const newItems3 = [...this.state.series];
            newItems3[this.state.currentQuestionIterator].yesNo1 = true;
            newItems3[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
            this.setState({
                series: newItems3, currentQuestionIterator: counter,
                previousStepCounter: step, loginConsentAgreedForQuestionnaire: true
            }, () => { this.increaseMasterOrderCount(); this.postNavigationAction(); });
        } else {
            if (this.state.inPositiveRepeatMode && step < 0) {
                var increasedPositiveRepeatCounter = this.state.positiveRepeatCount;
                this.setState({
                    positiveRepeatCount: increasedPositiveRepeatCounter + 1,
                    currentQuestionIterator: counter,
                    previousStepCounter: step,
                    isAnswerButtonDisabled: true
                }, () => {
                    this.increaseMasterOrderCount(); this.postNavigationAction();
                });
            }
            else if (this.state.inNegativeRepeatMode && step < 0) {
                var increasedNegativeRepeatCounter = this.state.negativeRepeatCount;
                this.setState({
                    negativeRepeatCount: increasedNegativeRepeatCounter + 1,
                    currentQuestionIterator: counter,
                    previousStepCounter: step, isAnswerButtonDisabled: true
                }, () => {
                    this.increaseMasterOrderCount(); this.postNavigationAction();
                });
            }
            else if (this.state.inStepPickerMode && step < 0) {
                var increasedStepPickerRepeatCounter = this.state.stepPickerRepeatCount;
                var increasedBotStepListIterator = this.state.botStepListIterator;
                this.setState(prevState => ({
                    stepPickerRepeatCount: increasedStepPickerRepeatCounter + 1,
                    botStepListIterator: increasedBotStepListIterator + 1,
                    currentQuestionIterator: counter,
                    previousStepCounter: step, isAnswerButtonDisabled: true,
                    botStepList: [...prevState.botStepList, {
                        stepValue: this.getBotStepValue(),
                        questionPlacement: this.state.currentQuestionIterator
                    }]
                }), () => {
                    this.increaseMasterOrderCount(); this.postNavigationAction();
                });
            }
            else {
                if (this.state.premiumConsentTracker && this.state.premiumBotSelected) {
                    var value = 0;
                    if (this.state.premiumBotSelected == "STANDARDPREMIUM" || this.state.premiumBotSelected == "PROPREMIER") value = 1;
                    this.setState({
                        currentQuestionIterator: counter + value,
                        previousStepCounter: step + value,
                        hideBackButton: true,
                        isAnswerButtonDisabled: true,
                    }, () => { if (!isStepPickerClick && !isRepeaterSetup && type) { this.increaseMasterOrderCount(true); } this.postNavigationAction(type) })
                } else
                    this.setState({
                        currentQuestionIterator: counter,
                        previousStepCounter: step,
                        isAnswerButtonDisabled: true
                    }, () => { if (!isStepPickerClick && !isRepeaterSetup && type) { this.increaseMasterOrderCount(); } this.postNavigationAction(type) })
            }
        }
    }
    increaseMasterOrderCount = () => {
        var increaseMasterOrderCounter = this.state.masterOrderCounter;
        this.setState({ masterOrderCounter: increaseMasterOrderCounter + 1 })
    }
    resetMasterOrderCount = () => {
        this.setState({ masterOrderCounter: 0 })
    }
    getBotStepValue = () => {
        if (this.state.series[this.state.currentQuestionIterator].question.positiveQuestionType == "LOGIN") {
            return "Login";
        }
        else {
            return this.state.series[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer1;
        }
    }
    getColumnFieldValue = () => {
        return this.state.series[this.state.currentQuestionIterator + 1].answer[this.state.positiveRepeatCount - 1].answer1;
    }
    handleNavigateBack = (e) => {
        var movement = Math.abs(this.state.previousStepCounter);
        var counter = this.state.currentQuestionIterator - movement;

        this.setState({ currentQuestionIterator: counter, previousStepCounter: 1, isAnswerButtonDisabled: false }, () => {
            if ((this.state.inPositiveRepeatMode || this.state.inNegativeRepeatMode || this.state.inStepPickerMode) &&
                this.state.repeaterQuestionIndex != this.state.currentQuestionIterator) {
                this.setState({ inPositiveRepeatMode: false, negativeRepeatCount: 0, inNegativeRepeatMode: false, stepPickerRepeatCount: 0, inStepPickerMode: false }, () => this.postNavigationAction())
            }
        })
    }

    postNavigationAction = (type) => {
        if (type && type == "NAMEBOT") {
            this.validateAnswer1Form(type);
        }
        if (type && type == "SHARETEETIMEBOTLOOKUP") {
            const series = [...this.state.series];
            let courseId = series.filter(function (item) {
                return item && item.answer && item.answer.questionType == "SHARETEETIMEBOTLOOKUP";
            }).map(function (item) {
                return item.answer.answer1;
            });
            if (courseId && courseId.length > 0 && courseId[0]) {
                ApiClient.post('bot/duplicate/minbot-day/' + courseId[0]).then((response) => {
                    if (response.status == 200) {
                        this.setState({ existingDay: response.data })
                    }
                }).catch((error) => {
                    alert(JSON.stringify(error.response.data));
                });
            }
        }

        if (type && type == "STEPPICKER") {
            this.setState({ hideBackButton: true })
        }
        if (this.state.series[this.state.currentQuestionIterator] && this.state.series[this.state.currentQuestionIterator].question.isRepeaterSetup &&
            (this.state.inPositiveRepeatMode || this.state.inNegativeRepeatMode || this.state.inStepPickerMode) &&
            this.state.repeaterQuestionIndex != this.state.currentQuestionIterator) {
            this.setState({
                positiveRepeatCount: 0,
                inPositiveRepeatMode: false, negativeRepeatCount: 0,
                inNegativeRepeatMode: false, stepPickerRepeatCount: 0,
                inStepPickerMode: false,
                repeaterQuestionIndex: this.state.currentQuestionIterator, botStepList: [], botStepListIterator: 0
            })
        }
        if (this.state.series[this.state.currentQuestionIterator] &&
            this.state.series[this.state.currentQuestionIterator].question.autoLabel) {
            this.setAutoLabelAnswer();
        }
        if (this.state.series[this.state.currentQuestionIterator] && this.state.series[this.state.currentQuestionIterator].question.isRepeaterSetup &&
            this.state.inPositiveRepeatMode && this.state.series[this.state.currentQuestionIterator].question.questionId == "605c261c411acf2d10d716e3") {//Export spreadsheet column question
            this.setState(prevState => ({
                columnFieldList: [...prevState.columnFieldList, { columnValue: this.getColumnFieldValue(), questionPlacement: this.state.currentQuestionIterator }]
            }), () => {
                var increasedColumnFieldListIterator = this.state.columnFieldListIterator;
                this.setState({ columnFieldListIterator: increasedColumnFieldListIterator + 1 })
            });
        }
        if ((this.state.inPositiveRepeatMode || this.state.inNegativeRepeatMode || this.state.inStepPickerMode) &&
            this.state.series[this.state.currentQuestionIterator] && !this.state.series[this.state.currentQuestionIterator].question.isRepeaterSetup
            && this.state.series[this.state.currentQuestionIterator].answer &&
            !Array.isArray(this.state.series[this.state.currentQuestionIterator].answer)) {
            this.setState({
                positiveRepeatCount: 0, negativeRepeatCount: 0,
                stepPickerRepeatCount: 0, inPositiveRepeatMode: false,
                inNegativeRepeatMode: false, inStepPickerMode: false, botStepList: [], botStepListIterator: 0
            });
        }
    }

    handleHideSeriesQuestionnaire = (show, showAlert) => {
        if (showAlert) {
            if (window.confirm("Are you sure you want close? Your Bot will not be saved.")) {
                if (show)
                    this.setState({ showSeriesQuestionnaireModal: show })
                else this.setState({ series: [], showSeriesQuestionnaireModal: show, currentQuestionIterator: 0, inStepPickerMode: false, inNegativeRepeatMode: false, inPositiveRepeatMode: false, stepPickerRepeatCount: 0, positiveRepeatCount: 0, negativeRepeatCount: 0, botStepList: [], botStepListIterator: 0, columnFieldList: [], columnFieldListIterator: 0 }, () => this.resetMasterOrderCount())
            }
        } else {
            if (show)
                this.setState({ showSeriesQuestionnaireModal: show })
            else this.setState({ series: [], showSeriesQuestionnaireModal: show, currentQuestionIterator: 0, inStepPickerMode: false, inNegativeRepeatMode: false, inPositiveRepeatMode: false, stepPickerRepeatCount: 0, positiveRepeatCount: 0, negativeRepeatCount: 0, botStepList: [], botStepListIterator: 0, columnFieldList: [], columnFieldListIterator: 0 }, () => this.resetMasterOrderCount())
        }

    }
    submitAnswers = () => {
        this.showIsLoading(true);
        let answers;
        let allArrayAnswers = this.state.series.filter((a => Array.isArray(a.answer))).map(a => a.answer).filter((k => k && k.some(o => o.order > -1))).flat().filter(o => o.order > -1)
        let allSingleAnswers = this.state.series.filter((a => a.answer && a.answer.order > -1)).map(a => a.answer);
        answers = allSingleAnswers.concat(allArrayAnswers).sort((a, b) => a.order - b.order);

        var finalAnswers = [];
        var finalOrderCounter = 0;

        for (var i = 0; i < answers.length; i++) {
            var item = answers[i];
            if (item.questionType == "DAYPICKER" && !item.answer1) continue;
            finalAnswers.push({
                questionId: item.questionId,
                questionType: item.questionType,
                answer1: item.questionType != "DAYPICKER" ? item.answer1 : item.answer1.replace(/^,|,$/g, ''),
                answer2: item.answer2,
                answer3: item.answer3,
                time: item.time,
                datePicker: item.datePicker,
                yesNo1: item.yesNo1,
                yesNo2: item.yesNo2,
                order: finalOrderCounter,
                isSpreadSheetFormField: item.isSpreadSheetFormField,
                isAfterSpreadSheetFormSubmitted: item.isAfterSpreadSheetFormSubmitted,
                isBeforeSpreadSheetForm: item.isBeforeSpreadSheetForm
            })
            finalOrderCounter++;
        }
        if (this.state.subCustomer) {
            ApiClient.post('bot/submit/answers/presubscription', { seriesId: this.state.currentSeriesId, answers: finalAnswers }).then((response) => {
                localStorage.setItem('readybot', JSON.stringify({ seriesId: this.state.currentSeriesId, answers: finalAnswers }));
                this.showIsLoading(false);
            }).catch((error) => {
                alert(JSON.stringify(error.response.data));

            });
        } else {
            ApiClient.post('bot/submit/answers', { seriesId: this.state.currentSeriesId, answers: finalAnswers }).then((response) => {
                this.showIsLoading(false);
                this.handleHideSeriesQuestionnaire(false);
                this.setState({ showAnimation: true }, () => {
                    this.setState({ isBotComplete: response.data.isActive }, () => {
                        this.handleFinishModalShow(true)
                    });
                });

            }).catch((error) => {
                alert(JSON.stringify(error.response.data));

            });
        }

    }
    setAutoLabelAnswer = () => {
        const label = this.state.series[this.state.currentQuestionIterator].question.autoLabel;
        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer1 = label;
            this.setState({ series: newItems1 });
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer1 = label;
            this.setState({ series: newItems2 });
        }
        else {
            const newItems3 = [...this.state.series];
            newItems3[this.state.currentQuestionIterator].answer.answer1 = label;
            this.setState({ series: newItems3 });
        }
    }
    handleStepPickerSelection = (questionType) => (e) => {
        if (questionType == "CreditCard") {
            var increasedBotStepListIterator = this.state.botStepListIterator;
            this.setState(prevState => ({
                inStepPickerMode: false, botStepListIterator: increasedBotStepListIterator + 1,
                botStepList: [...prevState.botStepList, { stepValue: "Credit Card", questionPlacement: this.state.currentQuestionIterator }]
            }), this.navigateQuestionnaire(2, undefined, false, undefined, undefined, undefined, true));
        } else {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator + 1].question.positiveQuestionType = questionType;
            newItems2[this.state.currentQuestionIterator + 1].answer[this.state.stepPickerRepeatCount].questionType = questionType;
            this.setState({ series: newItems2 }, this.navigateQuestionnaire(1, undefined, true, undefined, undefined, undefined, true));
        }

    }

    onClickDeleteBotListStep = (i, questionType, questionPlacement) => (e) => {
        if (questionType == "Credit Card") { window.alert("You cannot delete a credit card here. Please delete all credit card actions in the Edit Bot section after completing or you can restart your Bot setup by clicking the close button."); return; }
        var stepList = [...this.state.botStepList];

        stepList.splice(i, 1);
        const newItem = [...this.state.series];

        newItem[questionPlacement].answer[i].answer1 = null;
        newItem[questionPlacement].answer[i].answer2 = null;
        newItem[questionPlacement].answer[i].answer3 = null;
        newItem[questionPlacement].answer[i].datePicker = null;
        newItem[questionPlacement].answer[i].order = -1;

        this.setState({ botStepList: stepList, series: newItem });
    }
    onClickDeleteColumnFieldListStep = (i, questionPlacement) => (e) => {
        var columnList = [...this.state.columnFieldList];

        columnList.splice(i, 1);
        const newItem = [...this.state.series];

        newItem[questionPlacement + 1].answer[i].answer1 = null;
        newItem[questionPlacement + 1].answer[i].answer2 = null;
        newItem[questionPlacement + 1].answer[i].answer3 = null;
        newItem[questionPlacement + 1].answer[i].order = -1;

        this.setState({ columnFieldList: columnList, series: newItem });
    }
    handleAnswer1TextChange = (i, questionType, isFrequencySelect) => (e) => {

        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer1 = e.target.value;
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems1 }, () => this.validateAnswer1Form(questionType));
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer1 = e.target.value;
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter
            this.setState({ series: newItems2 }, () => this.validateAnswer1Form(questionType));
        }
        else if (this.state.inStepPickerMode) {
            const newItems3 = [...this.state.series];
            newItems3[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer1 = e.target.value;
            newItems3[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems3 }, () => this.validateAnswer1Form(questionType));
        }
        else {
            if (isFrequencySelect && this.invalidSubscriptionForFrequency(e)) {
                this.setState({ hideFrequencyErrorMessage: false });
                const newItems5 = [...this.state.series];
                newItems5[this.state.currentQuestionIterator].answer.answer1 = null;
                newItems5[this.state.currentQuestionIterator].answer.order = - 1;
                this.setState({ series: newItems5, hideFrequencyErrorMessage: false }, () => this.validateAnswer1Form(questionType));
                return;
            }
            const newItems4 = [...this.state.series];
            newItems4[this.state.currentQuestionIterator].answer.answer1 = questionType == "TIMEPICKER" ? e : questionType == "SHARETEETIMEBOTLOOKUP" ? e[0].id : e.target.value;
            if (questionType == "SHARETEETIMEBOTLOOKUP" && e && e[0].pricingType && e[0].pricingType != "STANDARD")
                this.setState({ premiumBotSelected: e[0].pricingType, premiumBotSelectedName: e[0].miniBotName })
            newItems4[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
            if (isFrequencySelect) newItems4[this.state.currentQuestionIterator].answer.time = null;
            this.setState({ series: newItems4, hideFrequencyErrorMessage: true }, () => this.validateAnswer1Form(questionType));
        }
    }
    onMiniBotMenuToggle = (next) => (isOpen) => {
        const selectedItem = [...this.state.series];
        if (!isOpen && selectedItem && selectedItem[this.state.currentQuestionIterator].answer.answer1) next();
    }
    invalidSubscriptionForFrequency = (selectedValue) => {
        if (this.state.subscriptionName == "Starter") {
            return true;
        }
        else if (this.state.subscriptionName == "Standard" && (selectedValue == "12 Hours" || selectedValue == "6 Hours")) {
            return false;
        }
        else if ((this.state.subscriptionName == "Pro" || this.state.subscriptionName == "Standard Plus") && (selectedValue == "2 Hours" || selectedValue == "60 Minutes" || selectedValue == "12 Hours" || selectedValue == "6 Hours")) {
            return false;
        }
        else if ((this.state.subscriptionName == "Pro Plus") && (selectedValue == "15 Minutes" || selectedValue == "30 Minutes" || selectedValue == "2 Hours" || selectedValue == "60 Minutes" || selectedValue == "12 Hours" || selectedValue == "6 Hours")) {
            return false;
        }
        else if (this.state.subscriptionName == "Elite") {
            return false;
        }
        else return true;
    }

    handleAnswer2TextChange = (i, which) => (e) => {

        switch (which) {
            case 0:
                if (this.state.inPositiveRepeatMode) {
                    const newItems1 = [...this.state.series];
                    newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer1 = e.target.value;
                    newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
                    this.setState({ series: newItems1 }, () => this.validateAnswer12Form());
                }
                else if (this.state.inNegativeRepeatMode) {
                    const newItems2 = [...this.state.series];
                    newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer1 = e.target.value;
                    newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter;
                    this.setState({ series: newItems2 }, () => this.validateAnswer12Form());
                }
                else if (this.state.inStepPickerMode) {
                    const newItems7 = [...this.state.series];
                    newItems7[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer1 = e.target.value;
                    newItems7[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
                    this.setState({ series: newItems7 }, () => this.validateAnswer12Form());
                }
                else {
                    const newItems3 = [...this.state.series];
                    newItems3[this.state.currentQuestionIterator].answer.answer1 = e.target.value;
                    newItems3[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
                    this.setState({ series: newItems3 }, () => this.validateAnswer12Form());
                }
                break;
            case 1:
                if (this.state.inPositiveRepeatMode) {
                    const newItems4 = [...this.state.series];
                    newItems4[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer2 = e.target.value;
                    newItems4[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
                    this.setState({ series: newItems4 }, () => this.validateAnswer12Form());
                }
                else if (this.state.inNegativeRepeatMode) {
                    const newItems5 = [...this.state.series];
                    newItems5[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer2 = e.target.value;
                    newItems5[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter;
                    this.setState({ series: newItems5 }, () => this.validateAnswer12Form());
                }
                else if (this.state.inStepPickerMode) {
                    const newItems8 = [...this.state.series];
                    newItems8[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer2 = e.target.value;
                    newItems8[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
                    this.setState({ series: newItems8 }, () => this.validateAnswer12Form());
                }
                else {
                    const newItems6 = [...this.state.series];
                    newItems6[this.state.currentQuestionIterator].answer.answer2 = e.target.value;
                    newItems6[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
                    this.setState({ series: newItems6 }, () => this.validateAnswer12Form());
                }
                break;
        }
    }

    handleAnswer3TextChange = (i, which, type, checkAll) => (e) => {
        switch (which) {
            case 0:
                if (this.state.inPositiveRepeatMode) {
                    const newItems1 = [...this.state.series];
                    newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer1 = e.target.value;
                    newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems1 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems1 }, () => this.validateAnswer12Form());
                    }

                }
                else if (this.state.inNegativeRepeatMode) {
                    const newItems2 = [...this.state.series];
                    newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer1 = e.target.value;
                    newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems2 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems2 }, () => this.validateAnswer12Form());
                    }
                }
                else if (this.state.inStepPickerMode) {
                    const newItems10 = [...this.state.series];
                    newItems10[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer1 = e.target.value;
                    newItems10[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems10 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems10 }, () => this.validateAnswer12Form());
                    }
                }
                else {
                    const newItems3 = [...this.state.series];
                    newItems3[this.state.currentQuestionIterator].answer.answer1 = e.target.value;
                    newItems3[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems3 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems3 }, () => this.validateAnswer12Form());
                    }
                }
                break;
            case 1:
                if (this.state.inPositiveRepeatMode) {
                    const newItems4 = [...this.state.series];
                    newItems4[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer2 = e.target.value;
                    newItems4[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems4 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems4 }, () => this.validateAnswer12Form());
                    }
                }
                else if (this.state.inNegativeRepeatMode) {
                    const newItems5 = [...this.state.series];
                    newItems5[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer2 = e.target.value;
                    newItems5[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems5 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems5 }, () => this.validateAnswer12Form());
                    }
                }
                else if (this.state.inStepPickerMode) {
                    const newItems11 = [...this.state.series];
                    newItems11[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer2 = e.target.value;
                    newItems11[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems11 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems11 }, () => this.validateAnswer12Form());
                    }
                }
                else {
                    const newItems6 = [...this.state.series];
                    newItems6[this.state.currentQuestionIterator].answer.answer2 = e.target.value;
                    newItems6[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems6 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems6 }, () => this.validateAnswer12Form());
                    }
                }
                break;
            case 2:
                if (this.state.inPositiveRepeatMode) {
                    const newItems7 = [...this.state.series];
                    newItems7[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer3 = e.target.value;
                    newItems7[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems7 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems7 }, () => this.validateAnswer12Form());
                    }
                }
                else if (this.state.inNegativeRepeatMode) {
                    const newItems8 = [...this.state.series];
                    newItems8[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer3 = e.target.value;
                    newItems8[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems8 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems8 }, () => this.validateAnswer12Form());
                    }
                }
                else if (this.state.inStepPickerMode) {
                    const newItems12 = [...this.state.series];
                    newItems12[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer3 = e.target.value;
                    newItems12[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems12 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems12 }, () => this.validateAnswer12Form());
                    }
                }
                else {
                    const newItems9 = [...this.state.series];
                    newItems9[this.state.currentQuestionIterator].answer.answer3 = e.target.value;
                    newItems9[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
                    if (type == "LISTSELECTOR" || type == "ADDSPREADSHEETCOLUMN") {
                        this.setState({ series: newItems9 }, () => this.validateAnswer123Form(checkAll));
                    } else {
                        this.setState({ series: newItems9 }, () => this.validateAnswer12Form());
                    }
                }
                break;
        }
    }
    datePickerValueChange = (i) => (e) => {
        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].datePicker = e;
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems1 }, () => this.validateDatePicker());
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].datePicker = e;
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems2 }, () => this.validateDatePicker());
        }
        else if (this.state.inStepPickerMode) {
            const newItems4 = [...this.state.series];
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].datePicker = e;
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems4 }, () => this.validateDatePicker());
        }
        else {
            const newItem3 = [...this.state.series];
            newItem3[this.state.currentQuestionIterator].answer.datePicker = e;
            newItem3[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
            this.setState({ series: newItem3 }, () => this.validateDatePicker());
        }
    }
    handleOnDatePickerRepeatOnChange = (e) => {
        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer2 = e.target.value;
            this.setState({ series: newItems1 }, () => this.validateDatePicker());
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer2 = e.target.value;
            this.setState({ series: newItems2 }, () => this.validateDatePicker());
        }
        else if (this.state.inStepPickerMode) {
            const newItems4 = [...this.state.series];
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer2 = e.target.value;
            this.setState({ series: newItems4 }, () => this.validateDatePicker());
        }
        else {
            const newItems3 = [...this.state.series];
            newItems3[this.state.currentQuestionIterator].answer.answer2 = e.target.value;
            this.setState({ series: newItems3 }, () => this.validateDatePicker());
        }

    }
    handlePickTimeOnChange = (i) => (e) => {
        if (!e) return;
        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer1 = e.format('HH:mm');
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems1 }, () => this.validateAnswer1Form());
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer1 = e.format('HH:mm');
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems2 }, () => this.validateAnswer1Form());
        }
        else if (this.state.inStepPickerMode) {
            const newItems4 = [...this.state.series];
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer1 = e.format('HH:mm');
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].order = this.state.masterOrderCounter;
            this.setState({ series: newItems4 }, () => this.validateAnswer1Form());
        }
        else {
            const newItems3 = [...this.state.series];
            newItems3[this.state.currentQuestionIterator].answer.answer1 = e.format('HH:mm');
            newItems3[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
            this.setState({ series: newItems3 }, () => this.validateAnswer1Form());
        }

    }
    handleYesNo1Change = (e) => {
        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].yesNo1 = e.target.checked
            this.setState({ series: newItems1 });
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].yesNo1 = e.target.checked
            this.setState({ series: newItems2 });
        }
        else if (this.state.inStepPickerMode) {
            const newItems4 = [...this.state.series];
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].yesNo1 = e.target.checked
            this.setState({ series: newItems4 });
        }
        else {
            const newItem3 = [...this.state.series];
            newItem3[this.state.currentQuestionIterator].answer.yesNo1 = e.target.checked;
            this.setState({ series: newItem3 });
        }

    }
    handleYesNo2Change = (e) => {
        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].yesNo2 = e.target.checked;
            this.setState({ series: newItems1 });
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].yesNo2 = e.target.checked;
            this.setState({ series: newItems2 });
        }
        else if (this.state.inStepPickerMode) {
            const newItems4 = [...this.state.series];
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].yesNo2 = e.target.checked;
            this.setState({ series: newItems4 });
        }
        else {
            const newItem3 = [...this.state.series];
            newItem3[this.state.currentQuestionIterator].answer.yesNo2 = e.target.checked;
            this.setState({ series: newItem3 });
        }
    }
    handleOnFieldTypeChange = (e) => {
        if (this.state.inPositiveRepeatMode) {
            const newItems1 = [...this.state.series];
            newItems1[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer3 = e.target.value == '--' ? null : e.target.value;
            this.setState({ series: newItems1 }, () => this.validateAnswer123Form(true));
        }
        else if (this.state.inNegativeRepeatMode) {
            const newItems2 = [...this.state.series];
            newItems2[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer3 = e.target.value == '--' ? null : e.target.value;
            this.setState({ series: newItems2 }, () => this.validateAnswer123Form(true));
        }
        else if (this.state.inStepPickerMode) {
            const newItems4 = [...this.state.series];
            newItems4[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer3 = e.target.value == '--' ? null : e.target.value;
            this.setState({ series: newItems4 }, () => this.validateAnswer123Form(true));
        }
        else {
            const newItems3 = [...this.state.series];
            newItems3[this.state.currentQuestionIterator].answer.answer3 = e.target.value == '--' ? null : e.target.value;
            this.setState({ series: newItems3 }, () => this.validateAnswer123Form(true));
        }

    }
    handleDayPickerOnChange = (i) => (e) => {
        const newItems = [...this.state.series];
        var currentValue = newItems[this.state.currentQuestionIterator].answer.answer1;
        if (currentValue == null || currentValue == "null") currentValue = "";
        switch (e.target.getAttribute("id")) {
            case 'chkSun':
                this.updateBotRunDaysFromQuestionnaire(i, currentValue, e.target.checked, 'SUNDAY,', /SUNDAY,/gi);
                break;
            case 'chkMon':
                this.updateBotRunDaysFromQuestionnaire(i, currentValue, e.target.checked, 'MONDAY,', /MONDAY,/gi);
                break;
            case 'chkTues':
                this.updateBotRunDaysFromQuestionnaire(i, currentValue, e.target.checked, 'TUESDAY,', /TUESDAY,/gi);
                break;
            case 'chkWed':
                this.updateBotRunDaysFromQuestionnaire(i, currentValue, e.target.checked, 'WEDNESDAY,', /WEDNESDAY,/gi);
                break;
            case 'chkThurs':
                this.updateBotRunDaysFromQuestionnaire(i, currentValue, e.target.checked, 'THURSDAY,', /THURSDAY,/gi);
                break;
            case 'chkFri':
                this.updateBotRunDaysFromQuestionnaire(i, currentValue, e.target.checked, 'FRIDAY,', /FRIDAY,/gi);
                break;
            case 'chkSat':
                this.updateBotRunDaysFromQuestionnaire(i, currentValue, e.target.checked, 'SATURDAY,', /SATURDAY,/gi);
                break;
        }
    }
    updateBotRunDaysFromQuestionnaire = (i, currentValue, isChecked, whichDay, replaceEx) => {
        if (isChecked) {
            const newItems = [...this.state.series];
            newItems[this.state.currentQuestionIterator].answer.answer1 = currentValue += whichDay;
            newItems[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
            this.setState({ series: newItems }, () => this.validateAnswer1Form());
        }
        else {
            const newItems = [...this.state.series];
            newItems[this.state.currentQuestionIterator].answer.answer1 = currentValue.replace(replaceEx, '');
            this.setState({ series: newItems }, () => this.validateAnswer1Form());
        }
    }
    handleTimePickerOnValueChange = (i) => (e) => {
        if (e) {
            const newItems = [...this.state.series];
            newItems[this.state.currentQuestionIterator].answer.time = e.format('HH:mm:ss');
            newItems[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
            newItems[this.state.currentQuestionIterator].answer.answer1 = null;
            this.setState({ series: newItems, isAnswerButtonDisabled: false, hideFrequencyErrorMessage: true });
        }


    }
    onSeriesWindowStartHour = (i) => (e) => {
        if (e) {
            const newItems = [...this.state.series];
            newItems[this.state.currentQuestionIterator].answer.answer2 = e.format('HH:mm').toString();
            this.setState({ series: newItems });
        }

    }
    onSeriesWindowEndHour = (i) => (e) => {
        if (e) {
            const newItems = [...this.state.series];
            newItems[this.state.currentQuestionIterator].answer.answer3 = e.format('HH:mm').toString();
            this.setState({ series: newItems });
        }

    }
    handleOnTimeZoneChange = (i) => (e) => {
        const newItems = [...this.state.series];
        newItems[this.state.currentQuestionIterator].answer.answer1 = e.target.value;
        newItems[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
        this.setState({ series: newItems }, () => this.validateAnswer1Form());
    }
    handleShowQuestionnairePasswordChange = (e) => {
        this.setState({ showQuestionnairePassword: e.target.checked });
    }
    handleShowImageHelperChange = (e) => {
        this.setState({ showQuestionImageHelper: e.target.checked });
    }

    validateAnswer1Form = (questionType) => {
        var answer = this.state.series[this.state.currentQuestionIterator].answer;
        if (this.state.inPositiveRepeatMode) {
            if (answer && answer[this.state.positiveRepeatCount].answer1) {
                if ((questionType == "URL" || questionType == "SPREADSHEETURL") && answer[this.state.positiveRepeatCount].answer1.length > 0) {

                    if (this.validateUrl(answer)) {
                        if (!answer[this.state.positiveRepeatCount].answer1.startsWith('http')) {
                            var newUrl = 'https://' + answer[this.state.positiveRepeatCount].answer1;
                            const newItems = [...this.state.series];
                            newItems[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].answer1 = newUrl;
                            this.setState({ series: newItems }, () => this.validateAnswer1Form(questionType));
                        }
                        else
                            this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                    else
                        this.setState({ isAnswerButtonDisabled: true, hideErrorMessage: false, errorMessageText: 'Invalid Website Link' });
                }
                else if (questionType == "EMAILRESULTS") {
                    if (this.validateEmail(answer[this.state.positiveRepeatCount].answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else if (questionType == "TEXTRESULTS") {
                    if (this.validatePhoneNumber(answer[this.state.positiveRepeatCount].answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else
                    this.setState({ isAnswerButtonDisabled: false })
            }
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else if (this.state.inNegativeRepeatMode) {
            if (answer && answer[this.state.negativeRepeatCount].answer1) {
                if ((questionType == "URL" || questionType == "SPREADSHEETURL") && answer[this.state.negativeRepeatCount].answer1.length > 0) {

                    if (this.validateUrl(answer)) {
                        if (!answer[this.state.negativeRepeatCount].answer1.startsWith('http')) {
                            var newUrl = 'https://' + answer[this.state.negativeRepeatCount].answer1;
                            const newItems = [...this.state.series];
                            newItems[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].answer1 = newUrl;
                            this.setState({ series: newItems }, () => this.validateAnswer1Form(questionType));
                        }
                        else
                            this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                    else
                        this.setState({ isAnswerButtonDisabled: true, hideErrorMessage: false, errorMessageText: 'Invalid Website Link' });
                }
                else if (questionType == "EMAILRESULTS") {
                    if (this.validateEmail(answer[this.state.negativeRepeatCount].answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else if (questionType == "TEXTRESULTS") {
                    if (this.validatePhoneNumber(answer[this.state.negativeRepeatCount].answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else
                    this.setState({ isAnswerButtonDisabled: false })
            }
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else if (this.state.inStepPickerMode) {
            if (answer && answer[this.state.stepPickerRepeatCount].answer1) {
                if ((questionType == "URL" || questionType == "SPREADSHEETURL") && answer[this.state.stepPickerRepeatCount].answer1.length > 0) {

                    if (this.validateUrl(answer)) {
                        if (!answer[this.state.stepPickerRepeatCount].answer1.startsWith('http')) {
                            var newUrl = 'https://' + answer[this.state.stepPickerRepeatCount].answer1;
                            const newItems = [...this.state.series];
                            newItems[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].answer1 = newUrl;
                            this.setState({ series: newItems }, () => this.validateAnswer1Form(questionType));
                        }
                        else
                            this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                    else
                        this.setState({ isAnswerButtonDisabled: true, hideErrorMessage: false, errorMessageText: 'Invalid Website Link' });
                }
                else if (questionType == "EMAILRESULTS") {
                    if (this.validateEmail(answer[this.state.stepPickerRepeatCount].answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else if (questionType == "TEXTRESULTS") {
                    if (this.validatePhoneNumber(answer[this.state.stepPickerRepeatCount].answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else
                    this.setState({ isAnswerButtonDisabled: false })
            }
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else {
            if (answer && answer.answer1) {
                if ((questionType == "URL" || questionType == "SPREADSHEETURL") && answer.answer1.length > 0) {
                    if (!answer.answer1.startsWith('http')) {
                        var newUrl = 'https://' + answer.answer1;
                        const newItems = [...this.state.series];
                        newItems[this.state.currentQuestionIterator].answer.answer1 = newUrl;
                        this.setState({ series: newItems });
                    }
                    //if (this.validateUrl(answer)) {
                    // if (!answer.answer1.startsWith('http')) {
                    //     var newUrl = 'https://' + answer.answer1;
                    // const newItems = [...this.state.series];
                    // newItems[this.state.currentQuestionIterator].answer.answer1 = newUrl;
                    // this.setState({ series: newItems }, () => this.validateAnswer1Form(questionType));
                    // }
                    // else
                    this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    // }
                    // else
                    //     this.setState({ isAnswerButtonDisabled: true, hideErrorMessage: false, errorMessageText: 'Invalid Website Link' });
                }
                else if (questionType == "EMAILRESULTS") {
                    if (this.validateEmail(answer.answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else if (questionType == "TEXTRESULTS") {
                    if (this.validatePhoneNumber(answer.answer1)) {
                        this.setState({ isAnswerButtonDisabled: false, hideErrorMessage: true, errorMessageText: '' });
                    }
                }
                else
                    this.setState({ isAnswerButtonDisabled: false })
            }
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
    }
    validateUrl = (answer) => {
        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);
        if (this.state.inPositiveRepeatMode) {
            if (answer[this.state.positiveRepeatCount].answer1.match(regex)) {
                return true;
            }
        }
        else if (this.state.inNegativeRepeatMode) {
            if (answer[this.state.positiveRepeatCount].answer1.match(regex)) {
                return true;
            }
        }
        else if (this.state.inStepPickerMode) {
            if (answer[this.state.stepPickerRepeatCount].answer1.match(regex)) {
                return true;
            }
        }
        else {
            if (answer.answer1.match(regex)) {
                return true;
            }
        }

        return false;
    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    validatePhoneNumber = (number) => {
        const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return re.test(number);
    }
    validateAnswer12Form = () => {
        var answer = this.state.series[this.state.currentQuestionIterator].answer;
        if (this.state.inPositiveRepeatMode) {
            if (answer && answer[this.state.positiveRepeatCount].answer1 && answer[this.state.positiveRepeatCount].answer2)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else if (this.state.inNegativeRepeatMode) {
            if (answer && answer[this.state.negativeRepeatCount].answer1 && answer[this.state.negativeRepeatCount].answer2)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else if (this.state.inStepPickerMode) {
            if (answer && answer[this.state.stepPickerRepeatCount].answer1 && answer[this.state.stepPickerRepeatCount].answer2)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else {
            if (answer && answer.answer1 && answer.answer2)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }

    }
    validateAnswer123Form = (checkAll) => {
        var answer = this.state.series[this.state.currentQuestionIterator].answer;
        if (!checkAll) {
            if (this.state.inPositiveRepeatMode) {
                if (answer && answer[this.state.positiveRepeatCount].answer2)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
            else if (this.state.inNegativeRepeatMode) {
                if (answer && answer[this.state.negativeRepeatCount].answer2)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
            else if (this.state.inStepPickerMode) {
                if (answer && answer[this.state.stepPickerRepeatCount].answer2)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
            else {
                if (answer && answer.answer2)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
        } else {
            if (this.state.inPositiveRepeatMode) {
                if (answer && answer[this.state.positiveRepeatCount].answer1 && answer[this.state.positiveRepeatCount].answer2 && answer[this.state.positiveRepeatCount].answer3)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
            else if (this.state.inNegativeRepeatMode) {
                if (answer && answer[this.state.positiveRepeatCount].answer1 && answer[this.state.negativeRepeatCount].answer2 && answer[this.state.negativeRepeatCount].answer3)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
            else if (this.state.inStepPickerMode) {
                if (answer && answer[this.state.stepPickerRepeatCount].answer1 && answer[this.state.stepPickerRepeatCount].answer2 && answer[this.state.stepPickerRepeatCount].answer3)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
            else {
                if (answer && answer.answer1 && answer.answer2 && answer.answer3)
                    this.setState({ isAnswerButtonDisabled: false })
                else
                    this.setState({ isAnswerButtonDisabled: true })
            }
        }


    }
    validateDatePicker = () => {
        var answer = this.state.series[this.state.currentQuestionIterator].answer;
        if (this.state.inPositiveRepeatMode) {
            if (answer && answer[this.state.positiveRepeatCount].answer1
                && this.state.series[this.state.currentQuestionIterator].answer[this.state.positiveRepeatCount].datePicker)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else if (this.state.inNegativeRepeatMode) {
            if (answer && answer[this.state.negativeRepeatCount].answer1
                && this.state.series[this.state.currentQuestionIterator].answer[this.state.negativeRepeatCount].datePicker)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else if (this.state.inStepPickerMode) {
            if (answer && answer[this.state.stepPickerRepeatCount].answer1
                && this.state.series[this.state.currentQuestionIterator].answer[this.state.stepPickerRepeatCount].datePicker)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }
        else {
            if (answer && answer.answer1
                && this.state.series[this.state.currentQuestionIterator].answer.datePicker)
                this.setState({ isAnswerButtonDisabled: false })
            else
                this.setState({ isAnswerButtonDisabled: true })
        }

    }
    acceptQuestionnaireFromLoginConsent = (step, i) => (e) => {
        const series = [...this.state.series];
        if (series && this.state.subCustomer) {
            let miniBotAdvanceDayPicker = series.filter(function (item) {
                return item && item.answer && item.answer.questionType == "MINIBOTADVANCEDAYPICKER";
            }).map(function (item) {
                return item.answer.answer1;
            });
            let courseId = series.filter(function (item) {
                return item && item.answer && item.answer.questionType == "SHARETEETIMEBOTLOOKUP";
            }).map(function (item) {
                return item.answer.answer1;
            });
            if (miniBotAdvanceDayPicker && miniBotAdvanceDayPicker.length > 0)
                miniBotAdvanceDayPicker = miniBotAdvanceDayPicker.filter(function (el) {
                    return el != null;
                });
            var teeTimeRanger = this.state.miniBotFinalTag;
            if (miniBotAdvanceDayPicker && miniBotAdvanceDayPicker.length > 0 && courseId && courseId.length > 0 && teeTimeRanger) {
                if (!this.state.premiumConsentTracker && this.state.premiumBotSelected) {
                    this.setState({ premiumConsentTracker: true, hideBackButton: true }, () => {
                        ApiClient.post('bot/submit/minibot', { botRunDays: miniBotAdvanceDayPicker[0], sharedBotId: courseId[0], compareText: teeTimeRanger }).then((response) => {
                        }).catch((error) => {
                            alert(JSON.stringify(error.response.data));
                        });
                    });
                }
                else {
                    ApiClient.post('bot/submit/minibot', { botRunDays: miniBotAdvanceDayPicker[0], sharedBotId: courseId[0], compareText: teeTimeRanger }).then((response) => {
                    }).catch((error) => {
                        alert(JSON.stringify(error.response.data));
                    });
                }

            }
            else {
                this.submitAnswers();
            }
        }
        const newItems = [...this.state.series];
        newItems[this.state.currentQuestionIterator].answer.yesNo1 = true;
        newItems[this.state.currentQuestionIterator].answer.order = this.state.masterOrderCounter;
        this.setState({ series: newItems }, this.navigateQuestionnaire(step, null, false, false, false));
    }
    discardQuestionnaireFromLoginConsent = () => {
        if (window.confirm('Are you sure you want to stop building your Bot? All data will not be saved.')) {
            this.handleHideSeriesQuestionnaire(false);
        }
    }
    showIsLoading = (show) => {
        this.setState({ isLoading: show });
    }
    checkSyncingRequired = (showInject) => {
        if (this.state.bot.isActive) {
            ApiClient.post('bot/require/sync', { ...this.state.bot }).then((response) => {
                if (response && response.data == true) {
                    this.setState({ bot: { ...this.state.bot, isActive: false }, activeMessage: 'Not Ready. <a href="javascript:;" onclick="window.location.reload()">Discard My Changes</a>' }, () => {
                        if (this.state.startedActive && showInject) {
                            this.setState({ showInjectModeAction: true }, () => this.showSyncWarning(true));
                        }
                        else if (this.state.startedActive && this.state.pendingTaskRemove) {
                            this.setState({ showRemoveAction: true }, () => this.showSyncWarning(true));
                        }
                        else
                            this.setState({ showInjectModeAction: false }, () => this.showSyncWarning(true));
                    });
                }
                else {
                    this.setState({ bot: { ...this.state.bot, isActive: true, startedActive: true }, activeMessage: 'Active' });
                }
            }).catch((error) => {
                alert(JSON.stringify(error.response.data));

            });
        }
    }
    checkUrlSync = () => {
        if (this.state.bot.isActive) {
            ApiClient.post('bot/require/sync', { ...this.state.bot }).then((response) => {
                if (response && response.data == true) {
                    this.setState({ bot: { ...this.state.bot, isActive: false } }, () => {
                        this.showChangeUrlWarning(true);
                    });
                }
            }).catch((error) => {
                alert(JSON.stringify(error.response.data));

            });
        }
    }
    continueWithBotChanges = () => {
        this.setState({ startedActive: false }, () => this.showSyncWarning(false))
    }
    showSyncWarning = (show) => {
        this.setState({ showSyncWarningModal: show });
    }
    showChangeUrlWarning = (show) => {
        this.setState({ showChangeUrlWarningModal: show });
    }
    showBusinessBotAlert = (show) => {
        this.setState({ showBusinessBotAlertModal: show });
    }
    showInjectModal = (show) => {
        this.setState({ showInjectHtmlModal: show });
    }
    forceUrlChange = () => {
        this.setState({ changingUrl: true }, () => {
            ApiClient.post('bot/force/change/url', {
                botId: this.state.bot.botId, newPath: this.state.bot.url
            }).then((response) => {
                if (response && response.status == 200) {
                    window.location.reload();
                }

            }).catch((error) => {
                this.setState({ injectMode: false, changingUrl: false });
                alert(JSON.stringify(error.response.data));
            });
        })

    }
    addTag = (tag) => {
        var index = this.textInput.props.inputProps.compareIndex;
        var exactMatch = this.textInput.props.inputProps.exactMatch;
        if (this.state.tags && this.state.tags.length > 26) return;
        if (tag && tag.text && tag.text.indexOf("%OR%") > -1) {
            var tagImport = tag.text.split("%OR%");
            if (tagImport) {
                if (tagImport.length > 26) return;
                var t = [];
                tagImport.forEach(item => {
                    t.push({ "id": item, "text": item })
                })
                this.setState({ tags: [...this.state.tags, ...t] }, () => {
                    var statement = this.buildORStatement();
                    this.setState({
                        bot: {
                            ...this.state.bot, taskInfo:
                                update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { options: { $splice: [[index, 1, { compareText: statement, exactMatch: exactMatch }]] } } } })
                        }
                    }, () => {
                        this.validateListSelectorHelperData();
                    });
                })
            }
        }
        else {
            this.setState({ tags: [...this.state.tags, tag] }, () => {
                var statement = this.buildORStatement();
                this.setState({
                    bot: {
                        ...this.state.bot, taskInfo:
                            update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { options: { $splice: [[index, 1, { compareText: statement, exactMatch: exactMatch }]] } } } })
                    }
                }, () => {
                    this.validateListSelectorHelperData();
                });
            })
        }

    }
    addMiniBotTag = (tag) => {
        if (this.state.miniBotTags && this.state.miniBotTags.length > 26) return;
        if (tag && tag.text && tag.text.indexOf("%OR%") > -1) {
            var tagImport = tag.text.split("%OR%");
            if (tagImport) {
                if (tagImport.length > 26) return;
                var t = [];
                tagImport.forEach(item => {
                    t.push({ "id": item, "text": item })
                })
                this.setState({ miniBotTags: [...this.state.miniBotTags, ...t] }, () => {
                    var statement = this.buildMiniBotORStatement();
                    this.setState({ miniBotFinalTag: statement });
                })
            }
        }
        else {
            this.setState({ miniBotTags: [...this.state.miniBotTags, tag] }, () => {
                var statement = this.buildMiniBotORStatement();
                this.setState({ miniBotFinalTag: statement });
            })
        }

    }
    removeTag = (index) => {
        var stateTags = [...this.state.tags];
        stateTags.splice(index, 1);
        var index = this.textInput.props.inputProps.compareIndex;
        var exactMatch = this.textInput.props.inputProps.exactMatch;
        this.setState({ tags: stateTags }, () => {
            var statement = this.buildORStatement();
            this.setState({
                bot: {
                    ...this.state.bot, taskInfo:
                        update(this.state.bot.taskInfo, { [this.state.currentTaskPosition]: { listSelectorTask: { options: { $splice: [[index, 1, { compareText: statement, exactMatch: exactMatch }]] } } } })
                }
            }, () => {
                this.validateListSelectorHelperData();
            });
        });
    }
    removeMiniBotTag = (index) => {
        var stateTags = [...this.state.miniBotTags];
        stateTags.splice(index, 1);
        this.setState({ miniBotTags: stateTags }, () => {
            var statement = this.buildMiniBotORStatement();
            this.setState({ miniBotFinalTag: statement });
        });
    }
    buildORStatement = () => {
        var tags = this.state.tags;
        if (!tags) {
            return null;
        }
        if (tags.length == 1) {
            return tags[0].text.trim();
        }
        var orBuilder = "";
        for (var i = 0; i < tags.length; i++) {
            if (i == 0)
                orBuilder += (tags[i].text.trim())
            else
                orBuilder += ("%OR%" + tags[i].text.trim())
        }
        return orBuilder;
    }
    buildMiniBotORStatement = () => {
        var tags = this.state.miniBotTags;
        if (!tags) {
            return null;
        }
        if (tags.length == 1) {
            return tags[0].text.trim();
        }
        var orBuilder = "";
        for (var i = 0; i < tags.length; i++) {
            if (i == 0)
                orBuilder += (tags[i].text.trim())
            else
                orBuilder += ("%OR%" + tags[i].text.trim())
        }
        return orBuilder;
    }
    destroyORStatement = (orStatement) => {
        var tags = [];
        if (!orStatement) {
            return null;
        }
        if (!orStatement[0].compareText) {
            return [];
        }
        if (orStatement[0].compareText.indexOf("%OR%") == -1) {
            tags.push({ "id": orStatement[0].compareText, "text": orStatement[0].compareText });
            return tags;
        }
        var splitOr = orStatement[0].compareText.split("%OR%");
        splitOr.forEach(item => {
            tags.push({ "id": item, "text": item })
        })
        return tags;
    }
    enterInjectMode = () => {
        this.setState({ injectMode: true }, () => this.showSyncWarning(false));
    }
    injectHtmlStep = () => {
        this.setState({ injectingHtml: true }, () => {
            ApiClient.post('bot/service/inject/html/step', {
                currentBotId: this.state.bot.botId, taskInfo: this.state.injectedTask, placement: this.state.injectModelPlacement,
                elementId: this.state.injectModeElementId, elementClass: this.state.injectModeElementClass, elementXpath: this.state.injectModeElementXpath
            }).then((response) => {
                if (response && response.status == 200) {
                    window.location.reload();
                }

            }).catch((error) => {
                this.setState({ injectMode: false });
                alert(JSON.stringify(error.response.data));
            });
        });
    }

    injectModeElementIdChange = (e) => {
        this.setState({ injectModeElementId: e.target.value, injectModeElementClass: '', injectModeElementXpath: '' })
    }
    injectModeElementClassChange = (e) => {
        this.setState({ injectModeElementId: '', injectModeElementClass: e.target.value, injectModeElementXpath: '' })
    }
    injectModeElementXpathChange = (e) => {
        this.setState({ injectModeElementId: '', injectModeElementClass: '', injectModeElementXpath: e.target.value })
    }
    removeAndRebuild = () => {
        this.setState({ removingStep: true }, () => {
            ApiClient.post('bot/service/remove/html/step', {
                currentBotId: this.state.bot.botId, taskInfo: this.state.pendingTaskRemove, removeIndex: this.state.pendingDeleteTask.index
            }).then((response) => {
                if (response && response.status == 200) {
                    window.location.reload();
                }

            }).catch((error) => {
                this.setState({ removingStep: false });
                alert(JSON.stringify(error.response.data));
            });
        });
    }

    render() {
        const rootStore = this.props.rootStore;
        return (
            <Container fluid={getParameterByName('bid', window.location.href)}>
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        {this.state.step !== 0 ? (
                            <div>
                                <h1>Create Your Bot</h1><a href="http://botit.com/setup.html" target="_blank" class="badge badge-info">Need help?</a>
                                <Row>
                                    <Col style={{ textAlign: 'center' }}>
                                        <span style={{ fontWeight: 'bold' }}>Bot Status: </span>
                                        <span dangerouslySetInnerHTML={{ __html: this.state.activeMessage }} style={{ wordWrap: 'break-word', fontWeight: 'bolder', color: this.state.bot.isActive ? 'green' : 'red' }}></span>
                                    </Col>
                                </Row>
                            </div>

                        ) :
                            (<div class="flex justify-between items-end"><h1 class="mainTitle">What do you want to automate today?</h1><div class="styles_buttonsContainer__FVOIu"></div></div>)

                        }

                    </Col>
                </Row>
                {this.state.step > 1 &&
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold' }}>Website:</span>  <span className="text-muted" style={{ wordWrap: 'break-word' }}> {this.state.bot.url}</span>
                        </Col>
                    </Row>
                }
                {this.state.step > 2 &&
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold' }}>Name:</span>  <span className="text-muted"> {this.state.bot.botName}</span>
                        </Col>
                    </Row>
                }
                <Templates
                    handleBuildMyOwn={this.handleBuildMyOwn}
                    hideTemplatePicker={this.state.step != 0}
                    startSeries={this.startSeries}
                    goToCreate={false}
                    templateOptions={this.state.templateOptions}
                    customTemplateOptions={this.state.customTemplateOptions}
                    preSubState={this.state.subCustomer}
                    subscriptionName={this.state.subscriptionName}
                    isEditing={this.state.isEditing}
                ></Templates>
                <SeriesQuestions
                    showSeriesQuestionnaireModal={this.state.showSeriesQuestionnaireModal}
                    handleHideSeriesQuestionnaire={this.handleHideSeriesQuestionnaire}
                    showQuestionImageHelper={this.state.showQuestionImageHelper}
                    series={this.state.series}
                    handleAnswer1TextChange={this.handleAnswer1TextChange}
                    handleAnswer2TextChange={this.handleAnswer2TextChange}
                    handleAnswer3TextChange={this.handleAnswer3TextChange}
                    datePickerValueChange={this.datePickerValueChange}
                    handleOnDatePickerRepeatOnChange={this.handleOnDatePickerRepeatOnChange}
                    handlePickTimeOnChange={this.handlePickTimeOnChange}
                    handleYesNo1Change={this.handleYesNo1Change}
                    handleYesNo2Change={this.handleYesNo2Change}
                    onMiniBotMenuToggle={this.onMiniBotMenuToggle}
                    navigateQuestionnaire={this.navigateQuestionnaire}
                    currentQuestionIterator={this.state.currentQuestionIterator}
                    currentSeriesId={this.state.currentSeriesId}
                    seriesTitle={this.state.seriesTitle}
                    exampleBotName={this.state.exampleBotName}
                    isAnswerButtonDisabled={this.state.isAnswerButtonDisabled}
                    hideErrorMessage={this.state.hideErrorMessage}
                    errorMessageText={this.state.errorMessageText}
                    handleShowImageHelperChange={this.handleShowImageHelperChange}
                    handleNavigateBack={this.handleNavigateBack}
                    handleShowQuestionnairePasswordChange={this.handleShowQuestionnairePasswordChange}
                    showQuestionnairePassword={this.state.showQuestionnairePassword}
                    acceptQuestionnaireFromLoginConsent={this.acceptQuestionnaireFromLoginConsent}
                    discardQuestionnaireFromLoginConsent={this.discardQuestionnaireFromLoginConsent}
                    handleDayPickerOnChange={this.handleDayPickerOnChange}
                    handleOnTimeZoneChange={this.handleOnTimeZoneChange}
                    handleTimePickerOnValueChange={this.handleTimePickerOnValueChange}
                    onSeriesWindowStartHour={this.onSeriesWindowStartHour}
                    onSeriesWindowEndHour={this.onSeriesWindowEndHour}
                    supportedTimeZones={this.state.supportedTimeZones}
                    repeatersAllowed={this.state.repeatersAllowed}
                    positiveRepeatCount={this.state.positiveRepeatCount}
                    negativeRepeatCount={this.state.negativeRepeatCount}
                    stepPickerRepeatCount={this.state.stepPickerRepeatCount}
                    inPositiveRepeatMode={this.state.inPositiveRepeatMode}
                    inNegativeRepeatMode={this.state.inNegativeRepeatMode}
                    inStepPickerMode={this.state.inStepPickerMode}
                    handleOnFieldTypeChange={this.handleOnFieldTypeChange}
                    handleStepPickerSelection={this.handleStepPickerSelection}
                    botStepList={this.state.botStepList}
                    columnFieldList={this.state.columnFieldList}
                    onClickDeleteBotListStep={this.onClickDeleteBotListStep}
                    onClickDeleteColumnFieldListStep={this.onClickDeleteColumnFieldListStep}
                    hideFrequencyErrorMessage={this.state.hideFrequencyErrorMessage}
                    hideBackButton={this.state.hideBackButton}
                    supportedGolfCourses={this.state.supportedGolfCourses}
                    supportedTennisCourts={this.state.supportedTennisCourts}
                    supportedPickleBallCourts={this.state.supportedPickleBallCourts}
                    supportedCampingSites={this.state.supportedCampingSites}
                    supportedDinnerSites={this.state.supportedDinnerSites}
                    miniBotSearchType={this.state.miniBotSearchType}
                    premiumBotSelectedName={this.state.premiumBotSelectedName}
                    addMiniBotTag={this.addMiniBotTag}
                    removeMiniBotTag={this.removeMiniBotTag}
                    setTextInputRef={this.setTextInputRef}
                    miniBotTags={this.state.miniBotTags}
                    existingDay={this.state.existingDay}
                />
                <StepOneUrl
                    botUrl={this.state.bot.url}
                    allowCustomRedirectUrl={this.state.bot.allowCustomRedirectUrl}
                    emailAddress={this.state.emailAddress}
                    handleUrlChange={this.handleUrlChange}
                    errorText={this.state.stepOneError}
                    disableStep1Button={this.state.disableStep1Button}
                    onSaveUrlNextClick={this.onSaveUrlNextClick}
                    step={this.state.step}
                    showUrlHelper={this.state.showUrlHelper}
                    handleShowUrlHelper={this.handleShowUrlHelper}
                    handleDontShowUrlHelperChange={this.handleDontShowUrlHelperChange}
                ></StepOneUrl>
                <StepTwoName
                    botName={this.state.bot.botName}
                    handleNameChange={this.handleNameChange}
                    onSaveNameClick={this.onSaveNameClick}
                    disableStep2Button={this.state.disableStep2Button}
                    onClickStepBack={this.onClickStepBack}
                    step={this.state.step}
                ></StepTwoName>
                <StepThreeTask
                    name={this.state.bot.name}
                    url={this.state.bot.url}
                    tasks={this.state.bot.taskInfo}
                    step={this.state.step}
                    modalFactory={this.modalFactory}
                    currentTaskPosition={this.state.currentTaskPosition}
                    showLoginTaskPassword={this.state.showLoginTaskPassword}
                    onTaskDragStart={this.onTaskDragStart}
                    onTaskDragEnd={this.onTaskDragEnd}
                    hideAssignButton={this.state.hideAssignButton}
                    dragInProgress={this.state.dragInProgress}
                    confirmDeleteModalShow={this.state.confirmDeleteModalShow}
                    handleConfirmDeleteShow={this.handleConfirmDeleteShow}
                    onClickConfirmDeleteTask={this.onClickConfirmDeleteTask}
                    pendingDeleteTask={this.state.pendingDeleteTask}
                    onClickDeleteTask={this.onClickDeleteTask}
                    showLoginConsentWarning={this.state.showLoginConsentWarning}
                    onAllowConsentClick={this.onAllowConsentClick}
                    onDoNotAllowConsentClick={this.onDoNotAllowConsentClick}
                    handleShowLoginConsentWarning={this.handleShowLoginConsentWarning}

                    modalShow={this.state.modalShow}
                    handleTaskModalShow={this.handleTaskModalShow}

                    loginModalShow={this.state.loginModalShow}
                    handleLoginModalShow={this.handleLoginModalShow}
                    handleLoginUsernameChange={this.handleLoginUsernameChange}
                    handleLoginPasswordChange={this.handleLoginPasswordChange}
                    onClickSaveLoginDetails={this.onClickSaveLoginDetails}
                    onShowPasswordChange={this.onShowPasswordChange}
                    disableLoginTaskButton={this.state.disableLoginTaskButton}


                    inputLabelHelperModalShow={this.state.inputLabelHelperModalShow}
                    handleInputHelperLabelModalShow={this.handleInputHelperLabelModalShow}
                    handleInputHelperLabelChange={this.handleInputHelperLabelChange}
                    handleInputValueChange={this.handleInputValueChange}
                    handleIsSpreadSheetFormField={this.handleIsSpreadSheetFormField}
                    handleIsSpreadSheetBeforeForm={this.handleIsSpreadSheetBeforeForm}
                    onClickSaveInputFieldDetails={this.onClickSaveInputFieldDetails}
                    disableInputTaskButton={this.state.disableInputTaskButton}

                    datePickerHelperModalShow={this.state.datePickerHelperModalShow}
                    handleDatePickerModalShow={this.handleDatePickerModalShow}
                    handleDatePickerOptionChange={this.handleDatePickerOptionChange}
                    handleDatePickerValueChange={this.handleDatePickerValueChange}
                    handleDatePickerRepeaterChange={this.handleDatePickerRepeaterChange}
                    onClickSaveDatePickerOptionDetails={this.onClickSaveDatePickerOptionDetails}
                    disableDatePickerTaskButton={this.state.disableDatePickerTaskButton}
                    repeatersAllowed={this.state.repeatersAllowed}

                    listSelectorHelperModalShow={this.state.listSelectorHelperModalShow}
                    handleListSelectorModalShow={this.handleListSelectorModalShow}
                    handleListSelectorOptionChange={this.handleListSelectorOptionChange}
                    handleListSelectorColumnOptionChange={this.handleListSelectorColumnOptionChange}
                    handleListSelectorValueOptionChange={this.handleListSelectorValueOptionChange}
                    onClickSaveListSelectorOptionDetails={this.onClickSaveListSelectorOptionDetails}
                    disableListSelectorTaskButton={this.state.disableListSelectorTaskButton}
                    addSearchOption={this.addSearchOption}
                    deleteItem={this.deleteItem}
                    hideAddNewSearchOption={this.state.hideAddNewSearchOption}
                    handleListSelectorExactMatchValueOptionChange={this.handleListSelectorExactMatchValueOptionChange}

                    selectOptionLabelHelperModalShow={this.state.selectOptionLabelHelperModalShow}
                    handleSelectOptionHelperLabelModalShow={this.handleSelectOptionHelperLabelModalShow}
                    handleSelectOptionHelperLabelChange={this.handleSelectOptionHelperLabelChange}
                    handleSelectValueChange={this.handleSelectValueChange}
                    onClickSaveSelectOptionDetails={this.onClickSaveSelectOptionDetails}
                    disableSelectTaskButton={this.state.disableSelectTaskButton}

                    buttonLabelHelperModalShow={this.state.buttonLabelHelperModalShow}
                    handleButtonHelperLabelModalShow={this.handleButtonHelperLabelModalShow}
                    handleButtonHelperLabelChange={this.handleButtonHelperLabelChange}
                    onClickSaveButtonDetails={this.onClickSaveButtonDetails}
                    disableButtonTaskButton={this.state.disableButtonTaskButton}

                    emailModalShow={this.state.emailModalShow}
                    handleEmailModalShow={this.handleEmailModalShow}
                    handleEmailChange={this.handleEmailChange}
                    onClickSaveEmailDetails={this.onClickSaveEmailDetails}
                    disableEmailResultsButton={this.state.disableEmailResultsButton}

                    smsModalShow={this.state.smsModalShow}
                    handleSmsModalShow={this.handleSmsModalShow}
                    handleSmsChange={this.handleSmsChange}
                    onClickSaveSmsDetails={this.onClickSaveSmsDetails}
                    disableSmsResultsButton={this.state.disableSmsResultsButton}
                    onClickEditTask={this.onClickEditTask}
                    onClickStepBack={this.onClickStepBack}
                    onClickStepForward={this.state.bot.botId && this.state.bot.runTimes && (this.state.bot.runTimes.length == 0 || (this.state.bot.runTimes[0].infiniteRun && !this.state.hasAccess)) ? this.onClickSaveBotData : this.onClickStepForward}
                    showUpgradeButton={this.showStepUpgradeButton}

                    allowCustomRedirectUrl={this.state.bot.allowCustomRedirectUrl}
                    forceScheduleReset={this.state.bot.forceScheduleReset}
                    runLocally={this.state.bot.runLocally}
                    hideFailedResults={this.state.bot.hideFailedResults}
                    skipRunTimeStep={this.state.bot.skipRunTimeStep}
                    skipIPSetup={this.state.bot.skipIPSetup}
                    skipDateInCreaseIfFail={this.state.bot.skipDateInCreaseIfFail}
                    extendPageLoadTimeout={this.state.bot.extendPageLoadTimeout}
                    sendNoResultsEmail={this.state.bot.sendNoResultsEmail}
                    continueAfterNoResults={this.state.bot.continueAfterNoResults}
                    dataCenterLocation={this.state.bot.dataCenterLocation}
                    iPSetting={this.state.bot.iPSetting}
                    driverHub={this.state.bot.driverHub}
                    proxyGroup={this.state.bot.proxyGroup}
                    burstDuration={this.state.bot.burstDuration}
                    burstCount={this.state.bot.burstCount}
                    miniBotId={this.state.bot.miniBotId}
                    maxDelayCount={this.state.bot.maxDelayCount}
                    mainBotStartDelay={this.state.bot.mainBotStartDelay}
                    customFailCount={this.state.bot.customFailCount}
                    latestFailRunElapse={this.state.bot.latestFailRunElapse}
                    mainBotUserId={this.state.bot.userId}
                    tabRecorderWebsiteTitle={this.state.bot.tabRecorderWebsiteTitle}
                    browserProfileId={this.state.bot.browserProfileId}
                    naturalScreenResolution={this.state.bot.naturalScreenResolution}
                    disableStealthProxy={this.state.bot.disableStealthProxy}
                    chainBotId={this.state.bot.chainBotId}
                    chainBotParams={this.state.bot.chainBotParams}
                    mainBotStaticIP={this.state.bot.staticIP}
                    dataFriendlyMessageKeys={this.state.bot.dataFriendlyMessageKeys}
                    windowIndexMultiplier={this.state.bot.windowIndexMultiplier}
                    country={this.state.bot.country}
                    handleBotDurationChange={this.handleBotDurationChange}
                    handleBurstCountChange={this.handleBurstCountChange}
                    handleMainBotTransferOwnership={this.handleMainBotTransferOwnership}
                    handleMiniBotChange={this.handleMiniBotChange}
                    handleOffSetScheduleTimeChange={this.handleOffSetScheduleTimeChange}
                    handleTabRecordingWebsiteTitle={this.handleTabRecordingWebsiteTitle}
                    handleBrowserProfileId={this.handleBrowserProfileId}
                    handleNaturalScreenResolution={this.handleNaturalScreenResolution}
                    handleDisableStealthProxy={this.handleDisableStealthProxy}
                    handleChainBotIdChange={this.handleChainBotIdChange}
                    handleChainBotParamChange={this.handleChainBotParamChange}
                    handleMainBotStaticIPChange={this.handleMainBotStaticIPChange}
                    handleDataFriendlyMessageKeysChange={this.handleDataFriendlyMessageKeysChange}
                    handleWindowIndexMultiplierChange={this.handleWindowIndexMultiplierChange}
                    handleBotCountryChange={this.handleBotCountryChange}
                    handleMaxDelayCountChange={this.handleMaxDelayCountChange}
                    dataCenterLocationOptions={this.state.dataCenterLocationOptions}
                    iPSettings={this.state.iPSettings}
                    driverHubOptions={this.state.driverHubOptions}
                    proxyGroupOptions={this.state.proxyGroupOptions}
                    chromeExtensionOptions={this.state.chromeExtensionOptions}
                    botCountryOptions={this.state.botCountryOptions}
                    startLurk={this.state.bot.startLurk}
                    isSpreadSheetBot={this.state.bot.isSpreadSheetBot}
                    targetBookingDate={this.state.bot.targetBookingDate}
                    advanceBookingDays={this.state.bot.advanceBookingDays}
                    offSetScheduleTime={this.state.bot.offSetScheduleTime}
                    handleShowUpdateRedirectUrl={this.handleShowUpdateRedirectUrl}
                    handleForceScheduleReset={this.handleForceScheduleReset}
                    handleRunLocallyOption={this.handleRunLocallyOption}
                    handleHideFailedResults={this.handleHideFailedResults}
                    handleSkipRunTimeStep={this.handleSkipRunTimeStep}
                    handleSkipIpSetup={this.handleSkipIpSetup}
                    handleSkipDateInCreaseIfFail={this.handleSkipDateInCreaseIfFail}
                    pauseAfterSuccess={this.state.bot.pauseAfterSuccess}
                    trackLastRunStatus={this.state.bot.trackLastRunStatus}
                    apiUseOnly={this.state.bot.apiUseOnly}
                    handlePauseAfterSuccess={this.handlePauseAfterSuccess}
                    handleApiUseOnly={this.handleApiUseOnly}
                    handleTrackLastRunStatus={this.handleTrackLastRunStatus}
                    handleLurkStart={this.handleLurkStart}
                    handleIsSpreadSheetBot={this.handleIsSpreadSheetBot}
                    handleExtendPageLoadTimeout={this.handleExtendPageLoadTimeout}
                    handleSendNoResultsEmail={this.handleSendNoResultsEmail}
                    handleContinueAfterNoResults={this.handleContinueAfterNoResults}
                    handleCustomRedirect={this.handleCustomRedirect}
                    showUpdateRedirectUrlModalShow={this.state.showUpdateRedirectUrlModalShow}
                    handleOnRedirectUrlChange={this.handleOnRedirectUrlChange}
                    onClickSaveHtmlControlRedirectUrl={this.onClickSaveHtmlControlRedirectUrl}
                    adminPanelModalShow={this.state.adminPanelModalShow}
                    handleShowAdminPanelModal={this.handleShowAdminPanelModal}
                    handleDataCenterLocationChange={this.handleDataCenterLocationChange}
                    handleIPSettingChange={this.handleIPSettingChange}
                    handleDriveHubChange={this.handleDriveHubChange}
                    handleProxyGroupChange={this.handleProxyGroupChange}
                    handleChromeExtensionChange={this.handleChromeExtensionChange}
                    chromeExtensionType={this.state.bot.chromeExtensionType}
                    emailAddress={this.state.emailAddress}
                    hasAccess={this.state.hasAccess}
                    botId={this.state.bot.botId}
                    handleMainBotStartDelayChange={this.handleMainBotStartDelayChange}
                    handleCustomFailCountChange={this.handleCustomFailCountChange}
                    handleLatestFailRunElapseChange={this.handleLatestFailRunElapseChange}
                    tags={this.state.tags}
                    addTag={this.addTag}
                    removeTag={this.removeTag}
                    setTextInputRef={this.setTextInputRef}
                />

                <StepFourSchedule
                    step={this.state.step}
                    onDragStart={this.onScheduleDragStart}
                    onDragEnd={this.onScheduleDragEnd}
                    dragInProgressSchedule={this.state.dragInProgressSchedule}
                    addScheduleModalShow={this.state.addScheduleModalShow}
                    handleAddTimeModalShow={this.handleAddTimeModalShow}
                    confirmDeleteScheduleModalShow={this.state.confirmDeleteScheduleModalShow}
                    handleConfirmDeleteScheduleShow={this.handleConfirmDeleteScheduleShow}
                    onClickConfirmDeleteSchedule={this.onClickConfirmDeleteSchedule}
                    pendingDeleteSchedule={this.state.pendingDeleteSchedule}
                    onDeleteScheduleItem={this.onDeleteScheduleItem}
                    handleOnCheckScheduleChange={this.handleOnCheckScheduleChange}
                    handleOnTimeZoneSelect={this.handleOnTimeZoneSelect}
                    onClickSaveNewSchedule={this.onClickSaveNewSchedule}
                    onScheduleValueChange={this.onScheduleValueChange}
                    disableScheduleButton={this.state.disableScheduleButton}
                    runTimes={this.state.bot.runTimes}
                    supportedTimeZones={this.state.supportedTimeZones}
                    hideAddTimeButton={this.state.hideAddTimeButton}
                    onClickEditSchedule={this.onClickEditSchedule}
                    currentSchedulePosition={this.state.currentSchedulePosition}
                    onClickStepBack={this.onClickStepBack}
                    onClickSaveBotData={this.onClickSaveBotData}
                    saveButtonText={this.state.saveButtonText}
                    disableSaveButton={this.state.disableSaveButton}
                    showUpgradeButton={this.showRunTimesUpgradeButton}
                    onFrequencyValueChange={this.onFrequencyValueChange}
                    hideFrequencyErrorMessage={this.state.hideFrequencyErrorMessage}
                    handleOnCheckFrequencyWindow={this.handleOnCheckFrequencyWindow}
                    handleOnCheckRunOnlineHoursOnly={this.handleOnCheckRunOnlineHoursOnly}
                    handleOnCheckRunOfflineHoursOnly={this.handleOnCheckRunOfflineHoursOnly}
                    onWindowStartHour={this.onWindowStartHour}
                    onWindowEndHour={this.onWindowEndHour}
                    handleMinuteBurstChange={this.handleMinuteBurstChange}
                    handleInfiniteRunChange={this.handleInfiniteRunChange}
                    emailAddress={this.state.emailAddress}
                    hasAccess={this.state.hasAccess}
                    containsBurst={this.state.bot.containBurst && !this.state.hasAccess}
                />
                <Finish
                    step={this.state.step}
                    showAnimation={this.state.showAnimation}
                    finishCreateBotModalShow={this.state.finishCreateBotModalShow}
                    handleFinishModalShow={this.handleFinishModalShow}
                    isBotComplete={this.state.isBotComplete}
                />
                <UpgradeNeededModal
                    show={this.state.showUpgradeNeededModal}
                    onHide={() => window.location = '/subscription'}
                    message={this.state.upgradeMessage}
                />
                <LoadingModal
                    show={this.state.isLoading}
                    onHide={() => this.showIsLoading(false)}
                    backdrop={'static'}
                    keyboard={false}
                />
                <SyncWarning
                    show={this.state.showSyncWarningModal}
                    onHide={() => this.showSyncWarning(false)}
                    continueWithBotChanges={this.continueWithBotChanges}
                    hasAccess={this.state.hasAccess}
                    backdrop={'static'}
                    showInjectModeAction={this.state.showInjectModeAction}
                    enterInjectMode={this.enterInjectMode}
                    showRemoveAction={this.state.showRemoveAction}
                    removeAndRebuild={this.removeAndRebuild}
                    removingStep={this.state.removingStep}
                />
                <UrlChangeWarning
                    show={this.state.showChangeUrlWarningModal}
                    onHide={() => this.showChangeUrlWarning(false)}
                    forceUrlChange={this.forceUrlChange}
                    changingUrl={this.state.changingUrl}
                    hasAccess={this.state.hasAccess}
                    backdrop={'static'}
                />
                <BusinessBotAlert
                    show={this.state.showBusinessBotAlertModal}
                    onHide={() => this.showBusinessBotAlert(false)}
                    backdrop={'static'}
                />
                <InjectModeModal
                    show={this.state.showInjectHtmlModal}
                    onHide={() => this.showInjectModal(false)}
                    injectModeElementId={this.state.injectModeElementId}
                    injectModeElementClass={this.state.injectModeElementClass}
                    injectModeElementXpath={this.state.injectModeElementXpath}
                    injectModeElementIdChange={this.injectModeElementIdChange}
                    injectModeElementClassChange={this.injectModeElementClassChange}
                    injectModeElementXpathChange={this.injectModeElementXpathChange}
                    injectHtmlStep={this.injectHtmlStep}
                    injectingHtml={this.state.injectingHtml}
                />
            </Container>
        )
    }
}
